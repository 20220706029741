/* eslint-disable consistent-return */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAudioPlayingNow, setAudioPlayingNow } from 'ducks/mediaObject';
import useWaveform from 'hooks/useWaveform';
import propTypes from 'prop-types';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm';

import playbackRates from 'constants/playbackRates';

import formatAudioTime from 'tools/formatAudioTime';
import getMediaPermissions from 'tools/getMediaPermissions';
import noop from 'tools/noop';

import WaveSurferPlayer from 'components/voiceMessage/WaveSurferPlayer';

import { theme } from 'styles/Theme';

const DEFAULT_TIME = 0;

const WaveSurferPlayerContainer = ({
  withRecording,
  currentVoice,
  isMessage,
  disabledVoiceButton,
  url,
  buttonRecordText,
  isRegistration,
  handleButtonClick,
  isLoading,
  audioFileName,
  handleAudioFileCreation,
  handleAudioFileDeletion,
  CustomButton,
  isCustomButton,
  messageValue,
  setMessageValue,
  messageSendHandler,
  onUploadMedia,
  mediaObject,
  isMediaLoadingStarted,
  clearUploadedMediaHandler,
  onVideoRecordClick,
  messageId,
  ...props
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [record, setRecord] = useState(null);

  const [uniqueAudioId] = useState(Math.random());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const [currentTime, setCurrentTime] = useState(DEFAULT_TIME);
  const [duration, setDuration] = useState(DEFAULT_TIME);
  const [recordingTime, setRecordingTime] = useState(DEFAULT_TIME);

  const [wavesurferUrl, setWavesurferUrl] = useState(url);

  const [playbackRate, setPlaybackRate] = useState([2]);

  const audioPlayingNowId = useSelector(selectAudioPlayingNow);

  const wavesurfer = useWaveform({
    containerRef,
    url: wavesurferUrl,
    options: props,
    progressColor: withRecording ? theme.colors.pink : theme.colors.primary,
    waveColor: withRecording ? theme.colors.white : theme.colors.grayMedium,
  });

  useEffect(() => {
    if (
      wavesurfer?.isPlaying() &&
      audioPlayingNowId &&
      audioPlayingNowId !== uniqueAudioId
    ) {
      wavesurfer.pause();
    }
  }, [audioPlayingNowId, uniqueAudioId, wavesurfer]);

  useEffect(() => {
    if (withRecording) {
      if (wavesurfer) {
        setRecord(wavesurfer.registerPlugin(RecordPlugin.create()));
      } else {
        setRecord(null);
      }
    }
  }, [wavesurfer, withRecording]);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearInterval(timerId);
        setTimerId(null);
      }
    };
  }, [timerId]);

  useEffect(() => {
    let subscriptions = [];
    if (withRecording) {
      if (!wavesurfer) return;

      if (record) {
        subscriptions = [
          record.on('record-start', () => {
            setIsPlaying(false);
            setIsRecording(true);
            setTimerId(
              setInterval(() => {
                setRecordingTime(sec => sec + 1);
              }, 1000),
            );
          }),
          record.on('record-end', async blob => {
            handleAudioFileCreation(blob, audioFileName);
            wavesurfer.destroy();
            setWavesurferUrl(URL.createObjectURL(blob));
            setIsRecording(false);
            setRecordingTime(DEFAULT_TIME);
            if (timerId) {
              clearInterval(timerId);
              setTimerId(null);
            }
          }),
        ];
      }
    }
    return () => {
      subscriptions.forEach(unsub => unsub());
    };
  }, [
    audioFileName,
    dispatch,
    handleAudioFileCreation,
    record,
    timerId,
    uniqueAudioId,
    wavesurfer,
    withRecording,
  ]);

  useEffect(() => {
    if (!wavesurfer) return;

    const subscriptions = [
      wavesurfer.on('play', () => {
        setIsPlaying(true);
      }),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('finish', () => setCurrentTime(DEFAULT_TIME)),
      wavesurfer.on('timeupdate', time => {
        if (!isRecording || isPlaying) {
          setCurrentTime(time);
        }
      }),
      wavesurfer.on('decode', audioDuration => {
        setDuration(audioDuration);
      }),
    ];

    return () => {
      subscriptions.forEach(unsub => unsub());
    };
  }, [dispatch, isPlaying, isRecording, messageId, uniqueAudioId, wavesurfer]);

  const onRecordClick = useCallback(() => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(null);
    }
    if (record) {
      if (record.isRecording()) {
        record.stopRecording();
        dispatch(setAudioPlayingNow(null));
      } else {
        record.startRecording();
        dispatch(setAudioPlayingNow(uniqueAudioId));
      }
    }
  }, [dispatch, record, timerId, uniqueAudioId]);

  const onStopClick = useCallback(() => {
    if (record) {
      dispatch(setAudioPlayingNow(null));
      record.stopRecording();
    }
  }, [dispatch, record]);

  const onPlayClick = useCallback(() => {
    if (wavesurfer.isPlaying()) {
      wavesurfer.pause();
      dispatch(setAudioPlayingNow(null));
    } else {
      wavesurfer.play();
      dispatch(setAudioPlayingNow(uniqueAudioId));
    }
  }, [dispatch, uniqueAudioId, wavesurfer]);

  const renderTime = useMemo(() => {
    if (isRecording) {
      return formatAudioTime(recordingTime);
    }
    if (isPlaying || currentTime !== DEFAULT_TIME) {
      return formatAudioTime(currentTime);
    }

    if (!isPlaying && currentTime === DEFAULT_TIME) {
      return formatAudioTime(duration);
    }
  }, [currentTime, duration, isPlaying, isRecording, recordingTime]);

  const handlePlaybackRateChange = useCallback(
    val => {
      if (wavesurfer) {
        wavesurfer.setPlaybackRate(playbackRates[val].value, true);
        wavesurfer.play();
        setPlaybackRate(val);
      }
    },
    [wavesurfer],
  );

  const [isActiveButton, setIsActiveButton] = useState(true);

  const handleRecordButtonClick = useCallback(() => {
    getMediaPermissions({
      onPermissionsGranted: () => {
        onRecordClick();
        setIsActiveButton(false);
      },
      video: false,
    });
  }, [onRecordClick]);

  const handleDeleteButtonClick = useCallback(() => {
    if (record && isRecording) {
      record.stopRecording();
    }
    if (wavesurfer.isPlaying()) {
      wavesurfer.pause();
      setIsPlaying(false);
    }
    handleAudioFileDeletion();
    setIsActiveButton(true);
  }, [handleAudioFileDeletion, isRecording, record, wavesurfer]);

  const handleSendButtonClick = useCallback(() => {
    handleButtonClick();
    handleDeleteButtonClick();
  }, [handleButtonClick, handleDeleteButtonClick]);

  return (
    <WaveSurferPlayer
      playbackRate={playbackRate}
      onRecordClick={onRecordClick}
      onPlayClick={onPlayClick}
      onStopClick={onStopClick}
      renderTime={renderTime}
      handlePlaybackRateChange={handlePlaybackRateChange}
      withRecording={withRecording}
      currentVoice={currentVoice}
      isMessage={isMessage}
      disabledVoiceButton={!!disabledVoiceButton}
      buttonRecordText={buttonRecordText}
      isRecording={isRecording}
      wavesurferUrl={wavesurferUrl}
      isPlaying={isPlaying}
      containerRef={containerRef}
      isRegistration={isRegistration}
      isActiveButton={isActiveButton}
      handleRecordButtonClick={handleRecordButtonClick}
      handleDeleteButtonClick={handleDeleteButtonClick}
      handleSendButtonClick={handleSendButtonClick}
      isLoading={isLoading}
      isCustomButton={isCustomButton}
      messageValue={messageValue}
      setMessageValue={setMessageValue}
      messageSendHandler={messageSendHandler}
      onUploadMedia={onUploadMedia}
      mediaObject={mediaObject}
      isMediaLoadingStarted={isMediaLoadingStarted}
      clearUploadedMediaHandler={clearUploadedMediaHandler}
      onVideoRecordClick={onVideoRecordClick}
    />
  );
};

WaveSurferPlayerContainer.defaultProps = {
  withRecording: false,
  isMessage: false,
  isRegistration: false,
  handleButtonClick: noop,
  audioFileName: 'audio.mp3',
};

WaveSurferPlayerContainer.propTypes = {
  url: propTypes.string,
  withRecording: propTypes.bool,
  currentVoice: propTypes.bool,
  isMessage: propTypes.bool,
  buttonRecordText: propTypes.string,
  isRegistration: propTypes.bool,
  handleButtonClick: propTypes.func,
  disabledVoiceButton: propTypes.any,
  isLoading: propTypes.bool,
  audioFileName: propTypes.string,
  handleAudioFileCreation: propTypes.func,
  handleAudioFileDeletion: propTypes.func,
  messageId: propTypes.string,
};

export default WaveSurferPlayerContainer;
