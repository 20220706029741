import { googleLogout } from '@react-oauth/google';
import humps from 'humps';
import i18next from 'i18next';

export const forceMyOwnLogout = () => {
  googleLogout();
};

const violationsMap = {
  categories: 0,
  specificInfo: 1,
  name: 2,
  surname: 2,
  country: 2,
  password: 2,
  email: 2,
  dateOfBirth: 2,
  gender: 2,
  nickname: 4,
  displayed_name: 4,
  bio: 4,
  city: 4,
  'company.name': 3,
  'company.address': 3,
  'company.postalCode': 3,
  'company.number': 3,
  'company.city': 3,
  tiktok: 4,
  snapchat: 4,
  instagram: 4,
  youtube: 4,
  twitter: 4,
};

export const getFirstCreatorViolationPage = violations => {
  const violationsArray = Object.keys(violations);
  return violationsArray.reduce((acc, violation) => {
    const violationPage = violationsMap[violation];

    if (violationPage !== undefined) {
      if (acc === null) {
        return violationPage;
      }
      return violationPage < acc ? violationPage : acc;
    }

    return acc;
  }, null);
};

export const getViolationsFieldNames = error =>
  error.violations.reduce((acc, { propertyPath }) => {
    if (propertyPath.search('social_networks') !== -1) {
      const [id] = propertyPath
        .match(/(?:\[).+?(?=\])/g)
        .map(string => string.replace(/[[\]]/g, ''));
      acc[error.requestData.social_networks[id].name.toLocaleLowerCase()] =
        i18next.t('validation.undefinedError');
    } else {
      acc[humps.camelize(propertyPath)] = i18next.t(
        'validation.undefinedError',
      );
    }

    return acc;
  }, {});
