import { IconLightning, IconMicrophone, IconVideo } from 'components/icons';

import { gradients, theme } from 'styles/Theme';

export const boostMessage = [
  {
    id: 1,
    value: 0,
    price: '1,49€',
    bgSlider: theme.colors.level1,
    colorSlider: theme.colors.level1,
    radioItemValue: 'Haute',
  },
  {
    id: 2,
    value: 1,
    price: '2,49€',
    bgSlider: gradients.boostLevel2,
    colorSlider: theme.colors.level2,
    radioItemValue: 'Élevée',
  },
  {
    id: 3,
    value: 2,
    price: '3,49€',
    bgSlider: gradients.boostLevel3,
    colorSlider: theme.colors.level3,
    radioItemValue: 'Maximale',
  },
  {
    id: 4,
    value: 3,
    price: '9,99€',
    bgSlider: gradients.boostLevel4,
    colorSlider: theme.colors.level4,
    radioItemValue: 'Vocal',
  },
  {
    id: 5,
    value: 4,
    price: '19,99€',
    bgSlider: gradients.secondary,
    colorSlider: gradients.secondary,
    radioItemValue: 'Vidéo',
  },
];

// donate - levels 1, 2, 3
export const visibleMessageRadioItems = [
  {
    id: 1,
    value: 0,
    visibleMessageText: 'Haute',
    icon: <IconLightning />,
  },
  {
    id: 2,
    value: 1,
    visibleMessageText: 'Élevée',
    icon: (
      <>
        <IconLightning />
        <IconLightning />
      </>
    ),
  },
  {
    id: 3,
    value: 2,
    secondValue: 3,
    thirdValue: 4,
    visibleMessageText: 'Maximale',
    icon: (
      <>
        <IconLightning />
        <IconLightning />
        <IconLightning />
      </>
    ),
  },
];

// for guaranteed answer
export const guaranteedAnswerRadioItems = [
  {
    id: 4,
    value: 3,
    visibleMessageText: 'Vocal',
    time: '24:33:44',
    icon: <IconMicrophone palette={theme.colors.black} size="16" />,
  },
  {
    id: 5,
    value: 4,
    visibleMessageText: 'Vidéo',
    time: '24:33:44',
    icon: <IconVideo palette={theme.colors.black} size="16" />,
  },
];
