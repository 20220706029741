export const apiRoutes = {
  MEDIA_OBJECT: {
    AMAZON_MEDIA: '/',
    MEDIA_OBJECTS: '/media-objects',
    MEDIA_OBJECT_DATA: '/media-objects/:id',
  },
  CATEGORY: {
    CATEGORIES: '/categories',
    CATEGORY_DATA: '/categories/:id',
  },
  CERTIFICATION: {
    CERTIFICATIONS: '/certifications',
    CERTIFICATIONS_SKIP: '/certifications/skip',
    CERTIFICATION_DATA: '/certifications/:id',
  },
  USER: {
    CURRENT_USER_DATA: '/me',
    CREATOR_DATA: '/creators/:id',
    FAN_DATA: '/fans/:id',
    CREATORS: '/creators',
    CREATORS_SEARCH: '/creators/search',
    TOP_CREATORS: '/creators/top',
    RESTORE_USER: '/users/me/restore',
    USERS: '/users',
    VALIDATE: '/users/validate',
    DELETE: '/delete-request',
  },
  DIALOG: {
    DIALOGS: '/dialogs',
    DIALOGS_SEARCH: '/dialogs/search',
    DIALOG_DATA: '/dialogs/:id',
    MEDIA_OBJECTS: '/dialogs/:id/media-objects',
    DIALOG_DATA_BY_CREATOR_NICKNAME: '/dialogs/creators/:nickname',
    DIALOG_DATA_BY_FAN_NICKNAME: '/dialogs/fans/:nickname',
  },
  MESSAGE: {
    MESSAGES: '/messages',
    MESSAGE_DATA: '/messages/:id',
    MESSAGES_DATA_BY_ID: '/dialogs/:id/messages',
    CREATOR_MESSAGES_DATA_BY_NICKNAME: '/dialogs/creators/:nickname/messages',
    FAN_MESSAGES_DATA_BY_NICKNAME: '/dialogs/fans/:nickname/messages',
    MESSAGE_PRICE_BY_ID: '/users/:id/prices/messages',
    MESSAGE_PRICE_BY_NICKNAME: '/creators/:nickname/prices/message',
    MESSAGE_GENERATE_PAYMENT: '/messages/:id/generate-payment',
    MASS: '/messages/mass',
  },
  PUBLICATION: {
    FEED: '/feed',
    PUSHED_MEDIA: '/pushed-media',
    USER_FEED_PUBLICATIONS: '/users/:id/feed',
    USER_FEED_PUBLICATION_DATA: '/users/:id/feed/:publicationId',
    USER_FEED_PUBLICATIONS_BY_NICKNAME: '/creators/:nickname/feed',
    FEED_PUBLICATION_DATA: '/feed/:publicationId',
    USER_PUSHED_PUBLICATIONS: '/users/:id/pushed-media',
    USER_PUSHED_PUBLICATION_DATA: '/users/:id/pushed-media/:publicationId',
  },
  INVOICE: {
    INVOICE_COLLECTION: '/invoices',
    INVOICE_DATA: '/invoices/:id',
  },
  AUTH: {
    LOGIN: '/login',
    GOOGLE_LOGIN: '/sso/google',
    TOKEN: '/token/refresh',
  },
  PROMO_CODE: {
    PROMO_CODES: '/promo-codes',
    PROMO_CODE_DATA: '/promo-codes/:id',
  },
  PAYMENTS: {
    PAYMENTS_LIST: '/payments',
    PURCHASE_DATA: '/purchases/:id',
  },
  PUSHED_MEDIA: {
    PUSHED_MEDIA: '/pushed-media',
    PUSHED_MEDIA_CODE: '/pushed-media/:code',
    PUSHED_MEDIA_CREATE_MESSAGE: '/pushed-media/:id/create-message',
    SUGGESTIONS: '/suggestions',
    GET_GOLD_OFFER: '/pushed-media/:code/prices/gold-offer',
    POST_GOLD_OFFER: '/pushed-media/:code/gold-offer',
  },
  RESET_PASSWORD: {
    RESET_PASSWORD_CHECK: '/reset-password/validate',
    RESET_PASSWORD_EMAIL: '/reset-password/send',
    RESET_PASSWORD_SETUP: '/reset-password/set',
  },
  SUBSCRIPTION: {
    SUBSCRIPTIONS: '/subscriptions',
    MY_SUBSCRIPTIONS: '/subscriptions/my',
    SUBSCRIPTION_DATA: '/subscriptions/:id',
    SUBSCRIPTION_PRICE: '/users/:id/prices/subscription',
    SUBSCRIPTION_MONTHLY_PRICES: '/users/:id/prices/monthly',
  },
  ALL_COUNTRIES: '/all',
  REACTIONS: {
    POST_REACTIONS: '/reactions',
    GET_PUBLICATION_REACTIONS: '/publications/:id/reactions',
    DELETE_REACTIONS: '/reactions/:id',
  },
  SERVER: '/server',
  DISCUSSION: {
    DISCUSSIONS: '/discussions',
    DISCUSSIONS_SEARCH: '/discussions/search',
  },
  SHARE: {
    SHARE: '/share',
    SHAREABLES: '/shareables',
    CODE: '/share/:urlCode',
  },
  REVENUE: {
    REVENUE: '/revenue',
    MY_SALES: '/revenue/my-sales',
    SHARED: '/revenue/shared',
    SHARES: '/revenue/shares',
    SHARES_PERIOD: '/revenue/shares/period',
    REFEREES_PERIOD: '/revenue/referees/period',
    AMOUNT: '/revenue/amount',
  },
  REPORT: '/reports',
  VERIFY_EMAIL: {
    SEND: 'verify-email/send',
    SET: 'verify-email/set',
  },
  BANK_ACCOUNTS: {
    COLLECTION: '/bank-accounts',
    ID: '/bank-accounts/:id',
  },
  PAYMENT_METHODS: {
    COLLECTION: '/payment-methods',
    ID: '/payment-methods/:id',
    SAVE: '/payment-methods/save',
    SETUP: '/payment-methods/setup',
  },
  PAYPAL_ACCOUNTS: {
    COLLECTION: '/paypal-accounts',
    ID: '/paypal-accounts/:id',
  },
  MAILING_SETTINGS: {
    WITHOUT_ID: '/mailing-settings',
    WITH_ID: '/mailing-settings/:id',
  },
};
