export const encodeImageFileAsURL = inputFile => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = e => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement('canvas');

        const isVertical = img.height >= img.width;

        canvas.width = isVertical ? img.height : img.width;
        canvas.height = isVertical ? img.height : img.width;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          img,
          0,
          0,
          isVertical ? img.height : img.width,
          isVertical ? img.height : img.width,
          isVertical ? canvas.width / 2 - img.width / 2 : 0,
          !isVertical ? canvas.height / 2 - img.height / 2 : 0,
          canvas.width,
          canvas.height,
        );

        const dataurl = canvas.toDataURL(inputFile.type);

        resolve(dataurl);
      };

      img.src = e.target.result;
    };

    temporaryFileReader.readAsDataURL(inputFile);
  });
};
