import PaymentMethodTemplate from 'pages/templates/PaymentMethodTemplate';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { MButton } from 'components/buttons';
import { MBox } from 'components/common';
import { IconBoost } from 'components/icons';
import { Text } from 'components/texts';

import { theme } from 'styles/Theme';

import { BaseModal } from './BaseModal';

const BoostPaymentFooterStyled = styled.footer`
  position: sticky;
  bottom: 0;
  background: ${theme.colors.white};
  z-index: 99;
  padding: ${theme.space.lg} 0;
`;

const BoostPaymentModal = ({ isOpen, setOpen, isFullListCards, ...props }) => {
  return (
    <BaseModal
      isOpen={isOpen}
      setOpen={setOpen}
      title="Paiement"
      closeButton
      height="100%"
      pb="0"
      {...props}
    >
      <MBox display="grid" gridTemplateRows="1fr auto" height="100%">
        <MBox textAlign="left">
          <PaymentMethodTemplate isFullListCards={isFullListCards} />
        </MBox>
        <BoostPaymentFooterStyled>
          <MButton icon primary width="100%" position="sticky" bottom="0">
            <Text
              as="span"
              display="flex"
              justifyContent="center"
              mr="primarySm"
              height="2rem"
            >
              <IconBoost />
            </Text>
            Payer 19,99€
          </MButton>
        </BoostPaymentFooterStyled>
      </MBox>
    </BaseModal>
  );
};

BoostPaymentModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
  isFullListCards: propTypes.bool,
};

export default BoostPaymentModal;
