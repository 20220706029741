import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import propTypes from 'prop-types';
import styled from 'styled-components';

import isIOS from 'tools/isIOS';
import noop from 'tools/noop';

import { ButtonGoBack, MButton } from 'components/buttons';
import { MBox } from 'components/common';
import {
  IconCross2,
  IconPlay,
  IconRevert,
  IconSend,
  IconStop2,
  IconVideo,
} from 'components/icons';
import { ProgressVideo } from 'components/messenger';
import { Text } from 'components/texts';

import { Transition } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const buttonStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.radii.circle};
  z-index: 999;
`;

const BlurStyles = `
  background: ${gradients.webCamBlur};
  backdrop-filter: blur(8px);
  position: relative;
`;

const ContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: ${breakpoints.md};
  aspect-ratio: 1/1;
  overflow: hidden;
  width: 96vw;
  height: 92vh;
  height: 92%; /* stylelint-disable-line */
  height: 92svh; /* stylelint-disable-line */
  margin-top: 4vh;
  border-radius: ${theme.radii.sm};
  z-index: 99;

  @media (min-width: ${breakpoints.xl}) {
    max-width: ${breakpoints.xxl};
    width: 100%;
  }

  .react-webcam {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
  }
`;

const ButtonStartStyled = styled.button`
  width: ${theme.sizes.recordVideoBtnSize};
  height: ${theme.sizes.recordVideoBtnSize};
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  ${buttonStyles};
  ${BlurStyles};
  ${Transition('transform')};
  ${props => props.capturing && `transform: scale(1.3);`};
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: ${theme.colors.white};
    z-index: -1;
    width: ${theme.sizes.recordVideoCircleSize};
    height: ${theme.sizes.recordVideoCircleSize};
    top: 1.2rem;
    left: 1.2rem;
    ${Transition('transform')};
    ${props => props.capturing && `transform: scale(0.44);`};
  }
`;

const ButtonRevertStyled = styled.button`
  width: ${theme.sizes.recordVideoRevertBtnSize};
  height: ${theme.sizes.recordVideoRevertBtnSize};
  background-color: ${theme.colors.white};
  position: absolute;
  right: calc((-${theme.sizes.recordVideoBtnSize} / 2) - 3.2rem);
  top: 2rem;
  ${buttonStyles};

  @media screen and (min-width: ${breakpoints.xl}) {
    display: none;
  }
`;

const CustomVideoRecordContainer = ({ onCloseModal, onSendVideo }) => {
  const FACING_MODE_USER = 'user';
  const FACING_MODE_ENVIRONMENT = 'environment';
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [mimeType, setMimeType] = useState('video/mp4');
  const [timerId, setTimerId] = useState(null);
  // video timer
  const MAX_SECONDS = 60;
  const [seconds, setSeconds] = useState(0);
  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };

  const [isPlayButton, setIsPlayButton] = useState(isIOS);

  const playButtonHandler = useCallback(() => {
    setIsPlayButton(false);
    const video = document.getElementById('video-replay');
    video.play();
  }, []);

  const handleReplay = useCallback(() => {
    if (!capturing && recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: mimeType,
      });
      const url = URL.createObjectURL(blob);
      const video = document.getElementById('video-replay');
      video.src = url;
      video.type = mimeType;
      // video.play();
    }
  }, [capturing, mimeType, recordedChunks]);

  useEffect(() => {
    if (!capturing && recordedChunks.length > 0) {
      handleReplay();
    }
  }, [recordedChunks, handleReplay, capturing]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(prev => prev.concat(data));
      }
    },
    [setRecordedChunks],
  );

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.removeEventListener(
          'dataavailable',
          handleDataAvailable,
        );
      }
    };
  }, [handleDataAvailable]);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearInterval(timerId);
        setTimerId(null);
      }
    };
  }, [timerId]);

  const handleStartCaptureClick = useCallback(() => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(null);
    }
    setCapturing(true);
    if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
      setMimeType('video/webm');
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: 'video/webm; codecs=vp9',
      });
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      setMimeType('video/webm');
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: 'video/webm',
      });
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      setMimeType('video/mp4');
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: 'video/mp4',
      });
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.removeEventListener(
        'dataavailable',
        handleDataAvailable,
      );
    }
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable,
    );
    mediaRecorderRef.current.start();
    setTimerId(
      setInterval(() => {
        setSeconds(seconds1 => seconds1 + 1);
      }, 1000),
    );
  }, [handleDataAvailable, timerId]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setSeconds(0);
  }, []);

  const sendVideoHandler = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: mimeType,
      });
      const file = new File([blob], mimeType.replace('/', '.'), {
        type: mimeType,
        lastModified: new Date().getTime(),
      });
      onSendVideo(file, mimeType.replace('/', '.'), 'video');
    }
  }, [mimeType, onSendVideo, recordedChunks]);

  const handleCameraRevert = useCallback(() => {
    setFacingMode(prevState =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  }, []);

  // video timer
  useEffect(() => {
    if (seconds === MAX_SECONDS) {
      handleStopCaptureClick();
    }
  }, [handleStopCaptureClick, seconds]);

  const clearRecorderVideo = useCallback(() => {
    setRecordedChunks([]);
  }, []);

  return (
    <ContainerStyled>
      <MBox
        position="absolute"
        top={!capturing && recordedChunks.length > 0 ? 'md' : 'sm'}
        left={!capturing && recordedChunks.length > 0 ? 'md' : 'sm'}
        zIndex="999"
      >
        {!capturing && recordedChunks.length > 0 ? (
          <ButtonGoBack
            size="8"
            palette={theme.colors.white}
            onClick={clearRecorderVideo}
          >
            <IconCross2 />
          </ButtonGoBack>
        ) : (
          <button
            type="button"
            aria-label="Fermer l'enregistrement vidéo"
            onClick={onCloseModal}
          >
            <IconCross2 />
          </button>
        )}
      </MBox>
      {!(!capturing && recordedChunks.length > 0) ? (
        <Webcam
          imageSmoothing
          ref={webcamRef}
          videoConstraints={{
            ...videoConstraints,
            facingMode,
          }}
          className="react-webcam"
          audio
          muted
        />
      ) : (
        <>
          <video
            className="react-webcam"
            id="video-replay"
            playsInline
            autoPlay
            loop
          />
          {isPlayButton && document.getElementById('video-replay')?.paused && (
            <MBox
              position="absolute"
              top="0"
              right="0"
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <button
                aria-label="Lire la vidéo"
                type="button"
                onClick={playButtonHandler}
              >
                <IconPlay size="100" />
              </button>
            </MBox>
          )}
        </>
      )}
      <MBox position="absolute" bottom="3.2rem">
        {!capturing && recordedChunks.length > 0 ? (
          <MButton
            aria-label="Envoyer une vidéo enregistrée"
            type="button"
            onClick={sendVideoHandler}
            rounded
            icon
            scale="sm"
          >
            Envoyer la vidéo
            <Text
              as="span"
              display="flex"
              justifyContent="center"
              ml="1rem"
              height="1.7rem"
            >
              <IconSend size="17" />
            </Text>
          </MButton>
        ) : (
          <>
            <ButtonStartStyled
              capturing={capturing}
              aria-label={
                capturing
                  ? "Arrêter d'enregistrer la vidéo"
                  : 'Commencer à enregistrer une vidéo'
              }
              type="button"
              onClick={
                capturing ? handleStopCaptureClick : handleStartCaptureClick
              }
            >
              {capturing ? (
                <>
                  <ProgressVideo progressTime={MAX_SECONDS} />
                  <IconStop2 />
                </>
              ) : (
                <IconVideo isGradient size="24" />
              )}
            </ButtonStartStyled>
            {!capturing && (
              <ButtonRevertStyled
                aria-label="Inverser la caméra"
                type="button"
                onClick={handleCameraRevert}
              >
                <IconRevert />
              </ButtonRevertStyled>
            )}
          </>
        )}
      </MBox>
    </ContainerStyled>
  );
};

CustomVideoRecordContainer.propTypes = {
  onCloseModal: propTypes.func,
  onSendVideo: propTypes.func,
};

CustomVideoRecordContainer.defaultProps = {
  onCloseModal: noop,
  onSendVideo: noop,
};

export default CustomVideoRecordContainer;
