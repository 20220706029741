import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  decrementSecondDialogsPage,
  getSecondDialogsRequest,
  selectIsDialogLoading,
  selectSecondDialogsPage,
} from 'ducks/dialog';
import debounce from 'lodash/debounce';

import { MBox } from 'components/common';
import { InputSearch } from 'components/forms';

import BestFansOfMounthList from './BestFansOfMounthList';
import MassMessageChatCard from './MassMessageChatCard';

const CreatorNewConversationLists = ({ multipleFansItems }) => {
  const dispatch = useDispatch();
  const [searchState, setSearchState] = useState('');
  const dialogsPage = useSelector(selectSecondDialogsPage);
  const isDialogLoading = useSelector(selectIsDialogLoading);

  const fetchMoreDataDialogs = useCallback(() => {
    dispatch(
      getSecondDialogsRequest({
        page: dialogsPage,
        publicName: searchState,
      }),
    );
  }, [dialogsPage, dispatch, searchState]);

  const loadMoreDialogs = useCallback(() => {
    if (!isDialogLoading) {
      fetchMoreDataDialogs();
    }
  }, [fetchMoreDataDialogs, isDialogLoading]);

  const getDialogsHandler = useCallback(
    searchValue => {
      dispatch(decrementSecondDialogsPage());
      dispatch(
        getSecondDialogsRequest({
          page: 1,
          publicName: searchValue,
        }),
      );
    },
    [dispatch],
  );

  const debouncedGetDialogsHandler = useMemo(
    () => debounce(getDialogsHandler, 400),
    [getDialogsHandler],
  );

  const searchDialogsHandler = useCallback(
    searchValue => {
      setSearchState(searchValue);
      debouncedGetDialogsHandler(searchValue);
    },
    [debouncedGetDialogsHandler],
  );

  const isMobile = useMemo(
    () =>
      /iphone|ipod|android|ie|blackberry|fennec/.test(
        navigator.userAgent.toLowerCase(),
      ),
    [],
  );

  return (
    <MBox textAlign="left">
      <InputSearch
        autoFocus={!isMobile}
        mb="primaryMd"
        value={searchState}
        onChange={searchDialogsHandler}
      />
      <MassMessageChatCard
        title="Multi destinataire"
        multipleFans
        items={multipleFansItems}
      />
      <BestFansOfMounthList
        title="Vos meilleurs fans ce mois-ci"
        loadMoreDialogs={loadMoreDialogs}
      />
    </MBox>
  );
};

export default CreatorNewConversationLists;
