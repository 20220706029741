import analyzeAudioBuffer from './analyzeAudioBuffer';

const convertBlobToMP3 = async (blob, audioFileName) => {
  const arrayBuffer = await blob.arrayBuffer();
  const audioContext = new AudioContext();

  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const mp3Blob = analyzeAudioBuffer(audioBuffer);

  const file = new File([mp3Blob], audioFileName, {
    type: 'audio/mpeg',
  });

  return file;
};

export default convertBlobToMP3;
