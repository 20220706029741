import { toast } from 'react-toastify';

const getMediaPermissions = async ({ onPermissionsGranted, video = true }) => {
  try {
    const permission = await navigator.permissions.query({ name: 'camera' });
    if (permission.state !== 'granted') {
      const localstream = await navigator.mediaDevices.getUserMedia({
        video,
        audio: true,
      });
      localstream?.getTracks().forEach(track => {
        track.stop();
      });
    }
    if (onPermissionsGranted) {
      onPermissionsGranted();
    }
  } catch (error) {
    toast.error(
      `Vous devez autoriser Premium à enregistrer des vidéos / audios. Rechargez la page pour relancer la demande d’autorisation.`,
      {
        position: toast.POSITION.TOP_CENTER,
      },
    );
    console.warn(error);
  }
};

export default getMediaPermissions;
