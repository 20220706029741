import { useCallback, useEffect, useState } from 'react';

import FocusTrap from 'focus-trap-react';
import propTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Modal, { ModalProvider } from 'styled-react-modal';
import { flexbox, layout, space } from 'styled-system';

import noop from 'tools/noop';

import { ButtonGoBack } from 'components/buttons';
import { Heading4, Text } from 'components/texts';

import {
  BaseModalStyled,
  CustomScrollBar,
  ModalOverlayStyled,
} from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

const StyledModal = Modal.styled`
  background-color: ${props => props.isBackgroundTransparent && 'transparent'};
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: ${props => props.opacity};
  transition: all 0.3s ease-in-out;
  ${props =>
    props.isCentered &&
    !props.isFullMedia &&
    !props.isBoost &&
    `width: 90%;
    border-bottom-left-radius: ${theme.radii.mdSecondary};
    border-bottom-right-radius: ${theme.radii.mdSecondary};
    margin: auto 0;`};
  ${props =>
    props.isFullMedia &&
    `border-radius: ${theme.radii.sm};
      margin: auto 1.3rem;`};
  ${props =>
    !props.isFullMedia &&
    !props.isCentered &&
    `overflow: hidden;
    width: 100%;
    max-height: 95%;`};
  ${props => !props.isBoost && !props.isFullMedia && BaseModalStyled()};
  ${props => (props.isBoost || props.isFullMedia) && 'max-height: 100%;'};
  ${props => props.isBoost && 'flex: 0 1 auto;'};
  ${CustomScrollBar};
  ${layout};
  @-moz-document url-prefix() {
    height: inherit;
  }
`;

const SpecialModalBackground = styled.div`
  ${ModalOverlayStyled()};
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  opacity: ${props => props.opacity};
`;

const ModalHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.space.primaryMd2} ${theme.space.lg};
  border-bottom: ${theme.borderWidths.xs} solid ${theme.colors.grayLighter};
`;

const ModalContentStyled = styled.div`
  ${props =>
    (props.title || props.hasBackButton) && `max-height: calc(90vh - 5%);`};
  ${props => props.isCropper && `max-height: 60vh;`};
  ${props =>
    !props.isFullMedia &&
    !props.isBoost &&
    `padding: ${theme.space.lg} ${theme.space.primaryMd};
      overflow: hidden auto;
      height: 100%;
    `};
  ${props =>
    !props.isFullMedia &&
    !props.isBoost &&
    !props.title &&
    !props.hasBackButton &&
    `max-height: 90vh;`};
  ${space};
  ${layout};
  ${flexbox};
`;

const ModalButtonStyled = styled.div`
  padding: ${theme.space.primaryMd};
`;

const ModalContainerStyled = styled.div`
  height: inherit;
  display: grid;
  ${props =>
    props.title || props.hasBackButton
      ? `grid-template-rows: minmax(5.6rem, min-content) 1fr;`
      : `grid-template-rows: 1fr`};
  ${props =>
    props.isCropper &&
    `grid-template-rows: minmax(5.6rem, min-content) 60vh auto;`};
`;

const ModalCloseButtonStyled = styled.button`
  position: absolute;
  right: ${theme.space.lg};
`;

const ModalWrapperStyled = styled.div`
  height: inherit;
  ${props =>
    !props.isFullMedia || (!props.isBoost && `height: fill-available;`)};
`;

export const BaseModal = ({
  children,
  isOpen,
  setOpen,
  onClose,
  title,
  titleColor,
  button,
  onGoBackClick,
  isCentered,
  closeButton,
  hasBackButton,
  isFullMedia,
  isBackgroundTransparent,
  isBoost,
  isCropper,
  ...props
}) => {
  const [opacity, setOpacity] = useState(0);
  const closeModal = useCallback(() => setOpen(false), [setOpen]);

  useEffect(() => {
    if (isOpen) setTimeout(() => setOpacity(1), 100);
  }, [isOpen]);

  const beforeClose = useCallback(() => {
    return new Promise(resolve => {
      document.body.classList.remove('no-scroll');
      setOpacity(0);
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        onClose();
        resolve();
      }, 300);
    });
  }, [onClose]);

  const beforeOpen = () => {
    document.body.classList.add('no-scroll');
  };

  const handleGoBackClick = useCallback(() => {
    if (onGoBackClick) {
      onGoBackClick(onClose);
    } else {
      onClose();
    }
  }, [onGoBackClick, onClose]);

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        <StyledModal
          isOpen={isOpen}
          opacity={opacity}
          isCentered={isCentered}
          isFullMedia={isFullMedia}
          isBoost={isBoost}
          role="dialog"
          aria-modal="true"
          aria-labelledby="ariaModalTitle"
          aria-describedby="ariaModalDesc"
          beforeOpen={beforeOpen}
          beforeClose={beforeClose}
          onBackgroundClick={
            onGoBackClick && hasBackButton ? handleGoBackClick : closeModal
          }
          onEscapeKeydown={
            onGoBackClick && hasBackButton ? handleGoBackClick : closeModal
          }
          isBackgroundTransparent={isBackgroundTransparent}
          {...props}
        >
          <FocusTrap
            active={isOpen && opacity === 1}
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              initialFocus: false,
            }}
          >
            <ModalWrapperStyled>
              <ModalContainerStyled
                hasBackButton={hasBackButton}
                title={title}
                isCropper={isCropper}
              >
                {(title || hasBackButton) && (
                  <ModalHeaderStyled
                    closeButton={closeButton}
                    isCentered={isCentered}
                    hasBackButton={hasBackButton}
                  >
                    {hasBackButton && (
                      <ButtonGoBack
                        position="absolute"
                        left="lg"
                        onClick={handleGoBackClick}
                      />
                    )}
                    {title && (
                      <Heading4
                        as="h2"
                        id="ariaModalTitle"
                        lineHeight="md"
                        fontSize={!isCentered ? 'md' : null}
                        maxWidth="75%"
                        lineClamp="2"
                        wordBreak="break-word"
                        color={titleColor}
                      >
                        {title}
                      </Heading4>
                    )}
                    {closeButton && (
                      <ModalCloseButtonStyled
                        type="button"
                        onClick={closeModal}
                      >
                        <Text $isGradient type="label2" as="span">
                          Annuler
                        </Text>
                      </ModalCloseButtonStyled>
                    )}
                  </ModalHeaderStyled>
                )}
                <ModalContentStyled
                  isFullMedia={isFullMedia}
                  isCropper={isCropper}
                  isBoost={isBoost}
                  hasBackButton={hasBackButton}
                  title={title}
                  {...props}
                >
                  {children}
                </ModalContentStyled>
                {button && <ModalButtonStyled>{button}</ModalButtonStyled>}
              </ModalContainerStyled>
            </ModalWrapperStyled>
          </FocusTrap>
        </StyledModal>
      </ModalProvider>
    </ThemeProvider>
  );
};

BaseModal.defaultProps = {
  onClose: noop,
  isBackgroundTransparent: false,
};

BaseModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
  onClose: propTypes.func,
  onGoBackClick: propTypes.func,
  children: propTypes.node.isRequired,
  button: propTypes.node,
  isCentered: propTypes.bool,
  title: propTypes.string,
  hasBackButton: propTypes.bool,
  closeButton: propTypes.bool,
  isFullMedia: propTypes.bool, // for image full and video full modals
  isBackgroundTransparent: propTypes.bool,
  isBoost: propTypes.bool, // for boost message modal
  isCropper: propTypes.bool,
  titleColor: propTypes.string,
};

export default BaseModal;
