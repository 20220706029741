/* eslint-disable no-nested-ternary */

/* eslint-disable react/display-name */
import { forwardRef, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import isNaN from 'lodash/isNaN';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { validateStatus } from 'constants/validateStatus';

import maxDateToSignup from 'tools/maxDateToSignup';
import minDateToSignup from 'tools/minDateToSignup';

import { LoadingSpinner } from 'components/common';
import { IconCalendar, IconCheckInput } from 'components/icons';
import { Text, TextLabel } from 'components/texts';

import {
  GradientBorderRadius,
  InputBaseStyles,
  InputDisabledStyles,
  InputIconStyles,
} from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

import { MInput } from '.';

const DatePickerWrapperStyled = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
    border: none;
  }

  .react-datepicker {
    font-family: ${theme.fonts.main};
    border-color: ${theme.colors.grayLight};
    border-radius: ${theme.radii.xs};
  }

  /* stylelint-disable */
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle:before {
    border-bottom-color: ${theme.colors.grayLight};
  }

  .react-datepicker__input-container {
    input {
      ${InputBaseStyles};
      padding-left: ${theme.space.primaryMd};
      height: ${theme.sizes.inputHeight};
      background: ${theme.colors.white};
      border: ${theme.borderWidths.xs} solid ${theme.colors.grayLight};
      color: ${theme.colors.black};
    }

    input::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    input[disabled] {
      ${InputDisabledStyles};
    }

    .react-datepicker__input--error {
      border: ${theme.borderWidths.sm} solid ${theme.colors.error};
    }

    input:focus {
      outline: none;
      ${GradientBorderRadius(`${gradients.primary}`, `${theme.radii.xs}`)};
    }

    input:focus-visible {
      box-shadow: none;
    }
  }

  .react-datepicker__month {
    margin: ${theme.space.md};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0 ${theme.space.md};
  }

  .react-datepicker__current-month {
    font-size: ${theme.fontSizes.sm};
    margin-bottom: ${theme.space.xs};
  }

  .react-datepicker {
    font-size: ${theme.fontSizes.sm};
  }

  .react-datepicker__triangle {
    transform: translate3d(5rem, 0, 0) !important;
  }

  .react-datepicker__day--selected {
    background: ${gradients.primary};
  }

  .react-datepicker__day--selected:hover {
    background: ${gradients.primary};
  }

  .react-datepicker__day {
    padding: 0.5rem;
  }

  .react-datepicker__day:not(.react-datepicker__day--selected):hover {
    background: ${theme.colors.grayLighter};
  }

  .react-datepicker__day--keyboard-selected {
    background: ${theme.colors.grayLighter};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: ${theme.sizes.datePickerDayWidth};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0.4rem;
  }

  .react-datepicker__header {
    background-color: ${theme.colors.white};
    border-bottom-color: ${theme.colors.grayLighter};
  }

  .react-datepicker__day-name {
    color: ${theme.colors.grayMedium};
  }

  .react-datepicker-popper {
    z-index: 999;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle:after {
    border-bottom-color: ${theme.colors.white};
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: ${theme.radii.xs};
    border-top-left-radius: ${theme.radii.xs};
  }
`;

const DatePickerLabelStyled = styled.label`
  position: relative;
  line-height: ${theme.lineHeights.lg};
  margin: 0;
  padding: 0;
`;

const MInputCheckMark = styled.span`
  ${InputIconStyles};
  right: ${theme.space.xxxl};
`;

const DatePickerContentStyled = styled.div`
  position: relative;
`;

const DatePickerButtonStyled = styled.button`
  position: absolute;
  right: ${theme.space.primaryMd};
  top: 0;
  bottom: 0;
  z-index: 99;
`;

const { SUCCESS, ERROR, LOADING } = validateStatus;

const CustomInput = forwardRef(
  (
    {
      onClick,
      stateStatus,
      datepickerId,
      disabled,
      value,
      onChange,
      onFocus,
      ...rest
    },
    ref,
  ) => {
    const changeHandler = useCallback(
      e => {
        if (e?.target?.value?.length === 3 && e?.target?.value[2] !== '/') {
          e.target.value = [
            e?.target?.value?.slice(0, 2),
            '/',
            e?.target?.value?.slice(2),
          ].join('');
        }
        if (e?.target?.value?.length === 6 && e?.target?.value[5] !== '/') {
          e.target.value = [
            e?.target?.value?.slice(0, 5),
            '/',
            e?.target?.value?.slice(5),
          ].join('');
        }
        if (e?.target?.value[e?.target?.value?.length - 1] === '/') {
          e.target.value = e?.target?.value.slice(0, -1);
        }
        onChange(e);
      },
      [onChange],
    );
    return (
      <DatePickerContentStyled onClick={onClick}>
        <MInput
          idLabel={datepickerId}
          focusRef={ref}
          type="text"
          value={value}
          onChange={changeHandler}
          onFocus={onFocus}
          {...rest}
          placeholder="jj / mm / aaaa"
        />
        <DatePickerButtonStyled type="button">
          <IconCalendar
            palette={
              stateStatus === ERROR
                ? `${theme.colors.error}`
                : disabled
                ? `${theme.colors.grayMedium}`
                : `${theme.colors.black}`
            }
          />
        </DatePickerButtonStyled>
      </DatePickerContentStyled>
    );
  },
);

const MDatePicker = ({
  label,
  value,
  errorText,
  stateStatus,
  datepickerId,
  onChange,
  disabled,
  onBlur,
}) => {
  const getIconStatus = useCallback(status => {
    switch (status) {
      case SUCCESS:
        return <IconCheckInput />;
      case ERROR:
        return null;
      case LOADING:
        return <LoadingSpinner my="0" size="16" position="relative" />;
      default:
        return null;
    }
  }, []);

  const changeHandler = useCallback(
    date => {
      if (date instanceof Date && !isNaN(date.getTime())) {
        onChange(date);
      } else {
        onChange(null);
      }
    },
    [onChange],
  );

  const changeRawHandler = useCallback(
    e => {
      if (e.target.value) {
        const dateParts = e.target.value.split('/');
        const dateObject = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0],
        );
        changeHandler(dateObject);
      }
    },
    [changeHandler],
  );

  return (
    <div>
      <DatePickerLabelStyled id={datepickerId}>
        {label && <TextLabel>{label}</TextLabel>}
      </DatePickerLabelStyled>
      <DatePickerWrapperStyled>
        <DatePicker
          locale="fr"
          selected={value}
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          onChange={changeHandler}
          onChangeRaw={changeRawHandler}
          customInput={
            <CustomInput
              stateStatus={stateStatus}
              datepickerId={datepickerId}
              disabled={disabled}
            />
          }
          minDate={minDateToSignup}
          maxDate={maxDateToSignup}
          onBlur={onBlur}
          showIcon
          className={stateStatus === ERROR && 'react-datepicker__input--error'}
          disabled={disabled}
          readOnly={disabled}
        />
        {stateStatus === SUCCESS && (
          <MInputCheckMark>{getIconStatus(stateStatus)}</MInputCheckMark>
        )}
      </DatePickerWrapperStyled>
      {stateStatus === ERROR && errorText && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          mt="xxs"
          color="error"
          id={`${datepickerId}Error`}
        >
          {errorText}
        </Text>
      )}
    </div>
  );
};

MDatePicker.defaultProps = {
  label: '',
  value: '',
  stateStatus: null,
};

MDatePicker.propTypes = {
  label: propTypes.string,
  stateStatus: propTypes.string,
  errorText: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
  onChange: propTypes.func,
  datepickerId: propTypes.string.isRequired,
  disabled: propTypes.bool,
};

export default MDatePicker;
