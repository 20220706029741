import propTypes from 'prop-types';

import { ButtonCircle } from 'components/buttons';
import { MBox } from 'components/common';
import { IconMicrophone, IconVideo } from 'components/icons';

import MessageTextarea from './MessageTextarea';

const MassMessageTextArea = ({
  messageValue,
  setMessageValue,
  messageSendHandler,
  onUploadMedia,
  mediaObject,
  isMediaLoadingStarted,
  clearUploadedMediaHandler,
  onAudioRecordClick,
  disabledAudioButton,
  onVideoRecordClick,
  isShow,
}) => {
  if (!isShow) return null;

  return (
    <MessageTextarea
      messageValue={messageValue}
      onChange={setMessageValue}
      placeholder="Votre message..."
      onSendClick={messageSendHandler}
      onMediaChange={onUploadMedia}
      mediaObject={mediaObject}
      isMediaLoadingStarted={isMediaLoadingStarted}
      onClearUploadedMedia={clearUploadedMediaHandler}
      rightWidget={
        <MBox display="flex" alignItems="center">
          <ButtonCircle
            mr="sm"
            onClick={onAudioRecordClick}
            disabled={disabledAudioButton}
          >
            <IconMicrophone size="50%" />
          </ButtonCircle>
          <ButtonCircle onClick={onVideoRecordClick}>
            <IconVideo size="40%" />
          </ButtonCircle>
        </MBox>
      }
    />
  );
};

MassMessageTextArea.propTypes = {
  messageValue: propTypes.string,
  setMessageValue: propTypes.func,
  messageSendHandler: propTypes.func,
  onUploadMedia: propTypes.func,
  mediaObject: propTypes.object,
  isMediaLoadingStarted: propTypes.bool,
  clearUploadedMediaHandler: propTypes.func,
  onAudioRecordClick: propTypes.func,
  onVideoRecordClick: propTypes.func,
  disabledAudioButton: propTypes.bool,
};

MassMessageTextArea.defaultProps = {
  messageValue: '',
  disabledAudioButton: false,
};

export default MassMessageTextArea;
