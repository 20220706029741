import { useMemo } from 'react';

import propTypes from 'prop-types';

import findSocialNetworkLink from 'tools/findSocialNetworkLink';

import { Avatar } from 'components/avatar';
import { MBox } from 'components/common';
import {
  IconFacebook,
  IconInstagram,
  IconSnapchat,
  IconTiktok,
  IconX,
  IconYoutube,
} from 'components/icons';
import { SocialList } from 'components/social';
import { Heading1, MList, Text } from 'components/texts';

import { theme } from 'styles/Theme';

const CreatorPublicProfileTemplate = ({ isModal, user }) => {
  const socialListItems = useMemo(
    () => [
      {
        id: 'Instagram',
        icon: (
          <IconInstagram
            palette={theme.colors.graySilver}
            size={theme.sizes.socialShareSize}
          />
        ),
        url: `https://instagram.com/${findSocialNetworkLink(
          user,
          'instagram',
        )}`,
        title: 'Instagram',
      },
      ...(findSocialNetworkLink(user, 'tiktok')
        ? [
            {
              id: 'Tiktok',
              icon: (
                <IconTiktok
                  palette={theme.colors.graySilver}
                  size={theme.sizes.socialShareSize}
                />
              ),
              url: `https://tiktok.com/@${findSocialNetworkLink(
                user,
                'tiktok',
              )}`,
              title: 'Tiktok',
            },
          ]
        : []),
      ...(findSocialNetworkLink(user, 'youtube')
        ? [
            {
              id: 'Youtube',
              icon: (
                <IconYoutube
                  palette={theme.colors.graySilver}
                  size={theme.sizes.socialShareSize}
                />
              ),
              url: `${findSocialNetworkLink(user, 'youtube')}`,
              title: 'Youtube',
            },
          ]
        : []),
      ...(findSocialNetworkLink(user, 'twitter')
        ? [
            {
              id: 'X',
              icon: (
                <IconX
                  palette={theme.colors.graySilver}
                  size={theme.sizes.socialShareSize}
                />
              ),
              url: `https://twitter.com/${findSocialNetworkLink(
                user,
                'twitter',
              )}`,
              title: 'X (anciennement Twitter)',
            },
          ]
        : []),
      ...(findSocialNetworkLink(user, 'facebook')
        ? [
            {
              id: 'Facebook',
              icon: (
                <IconFacebook
                  palette={theme.colors.graySilver}
                  size={theme.sizes.socialShareSize}
                />
              ),
              url: `${findSocialNetworkLink(user, 'facebook')}`,
              title: 'Facebook',
            },
          ]
        : []),
      ...(findSocialNetworkLink(user, 'snapchat')
        ? [
            {
              id: 'Snapchat',
              icon: (
                <IconSnapchat
                  palette={theme.colors.graySilver}
                  size={theme.sizes.socialShareSize}
                />
              ),
              url: `https://snapchat.com/add/${findSocialNetworkLink(
                user,
                'snapchat',
              ).toUpperCase()}`,
              title: 'Snapchat',
            },
          ]
        : []),
    ],
    [user],
  );

  return (
    <>
      <Avatar
        isChecked={user?.certified}
        type="xl"
        $src={user?.personalInfo?.avatar?.squarePreviewUrl}
        $srcSetAvif={user?.personalInfo?.avatar?.squarePreviewAvifUrl}
        $srcSetWebp={user?.personalInfo?.avatar?.squarePreviewWebpUrl}
        uniqueIdCheckedIcon="IconCheckAvatarPublicProfile"
      />
      <Heading1 as="p" lineHeight="lg">
        {user?.accountInfo.publicName}
      </Heading1>
      <MBox display="flex" alignItems="center" mb="sm">
        <Text as="span" color="grayMedium" lineHeight="lg">
          @{user?.accountInfo.username}
        </Text>
        <MList
          bulletMarker={!!user?.accountInfo.category}
          items={[
            {
              id: user?.accountInfo.category,
              text: user?.accountInfo.category,
            },
          ]}
          textType="label1"
        />
      </MBox>
      <Text
        type="label2"
        lineHeight="lg"
        mb="lg"
        {...(isModal && { id: 'ariaModalDesc' })}
      >
        {user?.personalInfo.bio}
      </Text>
      <MBox mb="primaryMd">
        <SocialList items={socialListItems} />
      </MBox>
    </>
  );
};

CreatorPublicProfileTemplate.defaultProps = {
  isModal: false,
};

CreatorPublicProfileTemplate.propTypes = {
  isModal: propTypes.bool,
  user: propTypes.object.isRequired,
};

export default CreatorPublicProfileTemplate;
