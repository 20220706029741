/* eslint-disable no-unused-expressions */

/* eslint-disable no-nested-ternary */

/* eslint-disable consistent-return */
import React from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';

import noop from 'tools/noop';

// import playbackRates from 'constants/playbackRates';
import { ButtonCircle, MButton } from 'components/buttons';
import { MBox } from 'components/common';
import {
  IconMicrophone,
  IconPause,
  IconPlayGradient,
  IconSend,
  IconStop,
  IconTrash2,
} from 'components/icons';
import MassMessageTextArea from 'components/messenger/helpers/MassMessageTextArea';
import { Text } from 'components/texts';

import { ButtonDisabledStyled } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const PlayerContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  gap: ${theme.space.primarySm};
  ${props =>
    !props.withRecording
      ? `border-radius: ${theme.radii.sm};
      padding: ${theme.space.primaryMd};
      max-width: 30.3rem;`
      : `border-radius: ${theme.radii.xl};
      background: ${gradients.primary};
      padding: ${theme.space.sm} ${theme.space.primaryMd} ${theme.space.sm} ${theme.space.sm};
      height: 4.4rem;`};
  ${props =>
    !props.isMessage &&
    !props.withRecording &&
    `border: 1px solid ${theme.colors.grayLighter}`};
`;

const SendButtonStyled = styled.button`
  ${props => props.disabled && ButtonDisabledStyled};
`;

const WaveSurferPlayer = ({
  withRecording,
  isRecording,
  isMessage,
  wavesurferUrl,
  disabledVoiceButton,
  // playbackRate,
  // handlePlaybackRateChange,
  onPlayClick,
  onStopClick,
  isPlaying,
  containerRef,
  renderTime,
  onRecordClick,
  currentVoice,
  handleDeleteButtonClick,
  isActiveButton,
  handleRecordButtonClick,
  handleSendButtonClick,
  isLoading,
  CustomButton,
  children,
  isCustomButton,
  messageValue,
  setMessageValue,
  messageSendHandler,
  onUploadMedia,
  mediaObject,
  isMediaLoadingStarted,
  clearUploadedMediaHandler,
  onVideoRecordClick,
  ...props
}) => {
  return (
    <>
      {/* in V1 not need speed slider or dropdown */}
      {/* {!isRecording && !!wavesurferUrl && (
        <MBox display="flex" alignItems="center" gap="1rem">
          <MRange
            value={playbackRate}
            onChange={val => handlePlaybackRateChange(val)}
            min={0}
            max={4}
            step={1}
          />
          <Text as="span" width="5rem">
            {playbackRates[playbackRate].displayed}
          </Text>
        </MBox>
      )} */}
      {/* VoiceMessage */}
      <MBox
        mb={!withRecording && !isMessage ? 'lg' : null}
        maxWidth={breakpoints.md}
        mx="auto"
        width="100%"
        display={
          withRecording && isActiveButton
            ? 'none'
            : withRecording && !isActiveButton
            ? 'grid'
            : null
        }
        gridTemplateColumns={
          withRecording && !isActiveButton ? `auto 1fr 2.4rem` : null
        }
        justifyContent={withRecording && 'space-between'}
        gap={theme.space.md}
        alignItem={withRecording && 'center'}
        className={withRecording && !isActiveButton ? 'react-recording' : null}
        {...props}
      >
        {!withRecording && !isMessage && (
          <Text type="body1" textAlign="center" as="h3" mb="sm">
            {currentVoice ? 'Votre vocal actuel :' : 'Exemple d’un vocal :'}
          </Text>
        )}
        {withRecording && (
          <button
            type="button"
            aria-label="Supprimer le message vocal"
            onClick={handleDeleteButtonClick}
          >
            <IconTrash2 />
          </button>
        )}
        <PlayerContainerStyled
          isMessage={isMessage}
          withRecording={withRecording}
          isRecording={isRecording}
        >
          {isRecording ? (
            <ButtonCircle
              scale="xxs"
              aria-label="Arrêter d'enregistrer le message vocal"
              onClick={onStopClick}
            >
              {/* TODO: temporary stop icon - need svg from Tristan */}
              <IconStop size="26" />
            </ButtonCircle>
          ) : (
            <ButtonCircle
              primary={isPlaying}
              type="button"
              onClick={onPlayClick}
              scale={withRecording ? 'xxs' : 'xxxs'}
              aria-label={
                isPlaying
                  ? 'Suspendre le message vocal'
                  : 'Lire un message vocal'
              }
            >
              {!withRecording ? (
                <>
                  {isPlaying ? (
                    <IconPause size="22" />
                  ) : (
                    <IconPlayGradient size="26" isGradient />
                  )}
                </>
              ) : (
                <>
                  {isPlaying ? (
                    <IconPause size="28" />
                  ) : (
                    <IconPlayGradient size="28" />
                  )}
                </>
              )}
            </ButtonCircle>
          )}
          <MBox
            width={!withRecording ? '14.7rem' : ['50%', '40rem', null, null]}
            ref={containerRef}
          />

          <Text
            type="body2"
            as="time"
            width="4rem"
            color={withRecording ? 'white' : null}
            $isGradient={!withRecording}
          >
            {renderTime}
          </Text>
        </PlayerContainerStyled>
        {withRecording && (
          <SendButtonStyled
            type="submit"
            aria-label="Envoyer un message vocal"
            onClick={handleSendButtonClick}
            disabled={isLoading || isRecording}
          >
            <IconSend isGradient />
          </SendButtonStyled>
        )}
      </MBox>
      {/* VoiceRecorder */}
      {withRecording && (
        <>
          {!isRecording && (
            <>
              {!isCustomButton ? (
                <MButton
                  display={!isActiveButton ? 'none' : 'block'}
                  icon
                  primary={!isMessage}
                  disabled={!!disabledVoiceButton}
                  rounded={isMessage}
                  width={isMessage ? '100%' : null}
                  onClick={handleRecordButtonClick}
                >
                  <Text
                    as="span"
                    display="flex"
                    justifyContent="center"
                    flexDirection={isMessage ? 'row-reverse' : 'row'}
                    height="2rem"
                  >
                    <IconMicrophone
                      size="20"
                      mr={!isMessage ? 'primarySm' : null}
                      ml={isMessage ? 'primarySm' : null}
                    />
                    {props.buttonRecordText}
                  </Text>
                </MButton>
              ) : (
                <MassMessageTextArea
                  messageValue={messageValue}
                  setMessageValue={setMessageValue}
                  messageSendHandler={messageSendHandler}
                  onUploadMedia={onUploadMedia}
                  mediaObject={mediaObject}
                  isMediaLoadingStarted={isMediaLoadingStarted}
                  clearUploadedMediaHandler={clearUploadedMediaHandler}
                  onAudioRecordClick={handleRecordButtonClick}
                  disabledAudioButton={!!disabledVoiceButton}
                  onVideoRecordClick={onVideoRecordClick}
                  isShow={isActiveButton}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

WaveSurferPlayer.defaultProps = {
  withRecording: false,
  isMessage: false,
  handleSendButtonClick: noop,
  isCustomButton: false,
};

WaveSurferPlayer.propTypes = {
  withRecording: propTypes.bool,
  currentVoice: propTypes.bool,
  isMessage: propTypes.bool,
  buttonRecordText: propTypes.string,
  handleDeleteButtonClick: propTypes.func,
  isActiveButton: propTypes.bool,
  handleRecordButtonClick: propTypes.func,
  handleSendButtonClick: propTypes.func,
  disabledVoiceButton: propTypes.any,
  isLoading: propTypes.bool,
  isCustomButton: propTypes.bool,
};

export default WaveSurferPlayer;
