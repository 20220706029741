import { useCallback } from 'react';

import CustomVideoRecordContainer from 'containers/CustomVideoRecordContainer';
import propTypes from 'prop-types';

import noop from 'tools/noop';

import { VisuallyHidden } from 'styles/MixinStyle';

import { BaseModal } from './BaseModal';

const VideoRecordModal = ({
  setOpen,
  onCloseButton,
  sendVideoHandler,
  ...props
}) => {
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const setOpenCustom = useCallback(
    state => {
      const videoElement = document.getElementById('video-replay');
      if (videoElement) {
        videoElement.pause();
        URL.revokeObjectURL(videoElement.src);
        videoElement.removeAttribute('src');
        videoElement.load();
      }
      setOpen(state);
    },
    [setOpen],
  );
  return (
    <BaseModal
      setOpen={setOpenCustom}
      isCentered
      isBackgroundTransparent
      isFullMedia
      height="100%"
      {...props}
    >
      <VisuallyHidden as="h2" id="ariaModalTitle">
        Modal with recording video
      </VisuallyHidden>
      <CustomVideoRecordContainer
        onCloseModal={onCloseButton || onClose}
        onSendVideo={sendVideoHandler}
      />
    </BaseModal>
  );
};

VideoRecordModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  sendVideoHandler: propTypes.func.isRequired,
  onCloseButton: propTypes.func,
};

CustomVideoRecordContainer.defaultProps = {
  sendVideoHandler: noop,
};

export default VideoRecordModal;
