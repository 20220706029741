import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  decrementSecondDialogsPage,
  reseSecondDialogsCollection,
  resetHasMoreSecondDialogs,
} from 'ducks/dialog';
import { selectUser } from 'ducks/user';
import propTypes from 'prop-types';

import CreatorNewConversationLists from 'components/messenger/helpers/CreatorNewConversationLists';
import MassMessageChat from 'components/messenger/helpers/MassMessageChat';

import { BaseModal } from './BaseModal';

const NewConversationCreatorModal = ({
  isOpen,
  setOpen,
  isAllFans,
  isBoostFans,
  isBestFans,
  messageText,
  ...props
}) => {
  const dispatch = useDispatch();
  const [massMessagesType, setMassMessagesType] = useState(
    (isAllFans && 'isAllFans') ||
      (isBoostFans && 'isBoostFans') ||
      (isBestFans && 'isBestFans') ||
      null,
  );
  const user = useSelector(selectUser);
  const [historyEmptyStatesCount, setHistoryEmptyStatesCount] = useState(0);

  const setOpenCustom = useCallback(
    state => {
      if (historyEmptyStatesCount > 0) {
        window.history.go(-historyEmptyStatesCount);
      }
      setOpen(state);
    },
    [historyEmptyStatesCount, setOpen],
  );

  const multipleFansItems = useMemo(
    () => [
      {
        id: 1,
        avatarAllFans: true,
        multipleDescription:
          'Chaque fan vous ayant envoyé au moins un message sur Premium',
        multipleTitle: 'Tous mes fans Premium',
        multipleQuantityFans: user?.fansNumber,
        onClick: () => {
          setMassMessagesType('isAllFans');
          window.history.pushState({}, null, null);
          setHistoryEmptyStatesCount(prev => prev + 1);
        },
      },
      {
        id: 2,
        avatarBoostFans: true,
        multipleDescription:
          'Chaque fan ayant utilisé au moins un boost pour vous envoyer un message.',
        multipleTitle: 'Fans utilisateur de boost',
        multipleQuantityFans: user?.contributorsNumber,
        onClick: () => {
          setMassMessagesType('isBoostFans');
          window.history.pushState({}, null, null);
          setHistoryEmptyStatesCount(prev => prev + 1);
        },
      },
      {
        id: 3,
        avatarBestFans: true,
        multipleDescription: 'Les 100 fans les plus consommateurs de boost',
        multipleTitle: 'Top 100 des meilleurs fans',
        multipleQuantityFans:
          user?.contributorsNumber < 100 ? user?.contributorsNumber : 100,
        onClick: () => {
          setMassMessagesType('isBestFans');
          window.history.pushState({}, null, null);
          setHistoryEmptyStatesCount(prev => prev + 1);
        },
      },
    ],
    [user?.contributorsNumber, user?.fansNumber],
  );

  useEffect(() => {
    return () => {
      dispatch(decrementSecondDialogsPage());
      dispatch(resetHasMoreSecondDialogs());
      dispatch(reseSecondDialogsCollection());
    };
  }, [dispatch]);

  const onBackButtonClick = useCallback(() => {
    setMassMessagesType(null);
    setHistoryEmptyStatesCount(prev => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  }, []);

  const onUIBackButtonClick = useCallback(() => {
    onBackButtonClick();
    window.history.back();
  }, [onBackButtonClick]);

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonClick);
    return () => {
      window.removeEventListener('popstate', onBackButtonClick);
    };
  }, [onBackButtonClick]);

  return (
    <BaseModal
      isOpen={isOpen}
      setOpen={setOpenCustom}
      pb={massMessagesType ? '0' : null}
      height="100%"
      title="Nouveau message"
      hasBackButton={!!massMessagesType}
      onGoBackClick={onUIBackButtonClick}
      closeButton
      {...props}
    >
      {!massMessagesType ? (
        <CreatorNewConversationLists multipleFansItems={multipleFansItems} />
      ) : (
        <MassMessageChat
          multipleFansItems={multipleFansItems}
          isAllFans={massMessagesType === 'isAllFans'}
          isBoostFans={massMessagesType === 'isBoostFans'}
          isBestFans={massMessagesType === 'isBestFans'}
          setOpen={setOpen}
          messageText={messageText}
        />
      )}
    </BaseModal>
  );
};

NewConversationCreatorModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
  isEmpty: propTypes.bool, // for static markup
  isAllFans: propTypes.bool,
  isBoostFans: propTypes.bool,
  isBestFans: propTypes.bool,
  messageText: propTypes.string,
};

export default NewConversationCreatorModal;
