import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearCreatorsBySearchData,
  decrementCreatorExplorePage,
  getCreatorsBySearchPaginationResetRequest,
  getCreatorsBySearchRequest,
  resetHasMore,
  selectCreatorExplorePage,
  selectCreators,
  selectHasMore,
  selectIsSearchLoading,
} from 'ducks/user';
import propTypes from 'prop-types';

import { MBox } from 'components/common';
import { InputSearch } from 'components/forms';
import { ShareList } from 'components/share';

import { BaseModal } from './BaseModal';

const NewConversationFanModal = ({ ...props }) => {
  const dispatch = useDispatch();
  const creatorExplorePage = useSelector(selectCreatorExplorePage);
  const creators = useSelector(selectCreators);
  const [searchNameValue, setSearchNameValue] = useState('');
  const isSearchLoading = useSelector(selectIsSearchLoading);
  const hasMoreCreators = useSelector(selectHasMore);

  useEffect(() => {
    dispatch(
      getCreatorsBySearchPaginationResetRequest({
        name: searchNameValue,
        setSearchNameValue,
        sort: 'dialogsNumber',
      }),
    );
  }, [dispatch, searchNameValue]);

  const fetchMoreDataCreators = useCallback(() => {
    dispatch(
      getCreatorsBySearchRequest({
        page: creatorExplorePage,
        name: searchNameValue,
        sort: 'dialogsNumber',
      }),
    );
  }, [creatorExplorePage, dispatch, searchNameValue]);

  const loadMoreCreators = useCallback(() => {
    if (!isSearchLoading && creators.length) {
      fetchMoreDataCreators();
    }
  }, [fetchMoreDataCreators, isSearchLoading, creators.length]);

  useEffect(() => {
    return () => {
      dispatch(decrementCreatorExplorePage());
      dispatch(resetHasMore());
      dispatch(clearCreatorsBySearchData());
    };
  }, [dispatch]);

  return (
    <BaseModal
      title="Nouvelle conversation"
      height="90vh"
      closeButton
      {...props}
    >
      <MBox textAlign="left">
        <InputSearch
          autoFocus
          value={searchNameValue}
          onChange={setSearchNameValue}
        />
        <ShareList
          withLink
          twoColumns
          creators={creators}
          loadMoreCreators={loadMoreCreators}
          hasMoreCreators={hasMoreCreators}
          isSubtitle={!creators.length && !isSearchLoading}
          isSearchLoading={isSearchLoading}
          searchNameValue={searchNameValue}
        />
      </MBox>
    </BaseModal>
  );
};

NewConversationFanModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
};

export default NewConversationFanModal;
