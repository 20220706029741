/* eslint-disable eqeqeq */
import { useCallback } from 'react';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { onChangeToArrayEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import { Text } from 'components/texts';

import { InputFocusVisible, Transition } from 'styles/MixinStyle';
import { breakpoints, theme } from 'styles/Theme';

const MRadioContainerStyled = styled.div`
  max-width: ${breakpoints.md};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  + * {
    margin-top: ${theme.space.secondaryMd};
  }
`;

const MRadioWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isThreeItem ? 'repeat(3, 31.5%)' : 'repeat(auto-fit, 48.5%)'};
  justify-content: center;
  grid-column-gap: ${theme.space.sm};
  height: ${theme.sizes.boostRadioHeight};
  max-width: ${theme.sizes.boostRadioWrapperWidth};
  margin: 0 auto;
`;

const MRadioItemStyled = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${theme.radii.sm};
  padding: ${theme.space.sm} ${theme.space.primaryMd};
  background: ${theme.colors.white};
  opacity: 0.2;
  height: inherit;
  ${props => props.active && `opacity: 1;`};
  ${props =>
    props.labelDisabled
      ? `opacity: 0.2; cursor: default;`
      : 'cursor: pointer;'};
  ${Transition('opacity')};
`;

const MRadioInputStyled = styled.input`
  ${InputFocusVisible};
`;

const BoostRadio = ({
  name,
  items,
  value,
  label,
  onChange,
  ariaLabelledby,
  isThreeItem,
  isGuaranteedResponseSend,
  onDisabledStateClick,
  ...props
}) => {
  const changeHandler = useCallback(
    event => onChangeToArrayEventHandler(event, onChange),
    [onChange],
  );
  return (
    <MRadioContainerStyled>
      {label && (
        <Text
          as="span"
          type="subtitle1"
          color="white"
          display="block"
          textAlign="center"
          mb="sm"
          id={ariaLabelledby}
        >
          {label}
        </Text>
      )}
      <MRadioWrapperStyled isThreeItem={isThreeItem} {...props}>
        {items?.map(item => (
          <MRadioItemStyled
            active={
              value == item.value ||
              value == item.secondValue ||
              value == item.thirdValue
            }
            htmlFor={`${name}-${item.id}`}
            key={item.id}
            labelDisabled={isGuaranteedResponseSend}
          >
            <MRadioInputStyled
              onClick={
                isGuaranteedResponseSend ? onDisabledStateClick : changeHandler
              }
              id={`${name}-${item.id}`}
              type="radio"
              name={name}
              value={item.value}
              aria-labelledby={`${ariaLabelledby} ${item.id}`}
            />
            <Text
              as="span"
              display="flex"
              width="100%"
              justifyContent="center"
              height="1.3rem"
              gap={theme.space.xxs}
              mb="xxs"
            >
              {item.icon}
            </Text>
            <Text as="span" type="subtitle2" id={`${item.id}`}>
              {isGuaranteedResponseSend ? (
                <>{item.time}</>
              ) : (
                <>{item.visibleMessageText}</>
              )}
            </Text>
          </MRadioItemStyled>
        ))}
      </MRadioWrapperStyled>
    </MRadioContainerStyled>
  );
};

BoostRadio.defaultProps = {
  onChange: noop,
  isThreeItem: false,
  onDisabledStateClick: noop,
};

BoostRadio.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string,
  ariaLabelledby: propTypes.string,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      value: propTypes.any.isRequired,
      icon: propTypes.node,
      visibleMessageText: propTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  value: propTypes.any,
  onChange: propTypes.func,
  isGuaranteedResponseSend: propTypes.bool,
  isThreeItem: propTypes.bool,
  onDisabledStateClick: propTypes.func,
};

export default BoostRadio;
