import propTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import { LinkComponent } from 'components/texts';

import { CustomScrollBar } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

const SocialListStyled = styled.ul`
  max-width: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0 ${theme.space.xxxs} ${theme.space.xxxs} 0;
  overflow-x: auto;
  overflow-y: hidden;
  ${CustomScrollBar};
  ${space};
`;

const SocialListItemStyled = styled.li`
  + li {
    margin-left: ${theme.space.lg};
  }

  a {
    display: flex;
    align-items: center;
  }
`;

const SocialList = ({ items, ...props }) => {
  return (
    <SocialListStyled {...props}>
      {items.map(item => (
        <SocialListItemStyled key={item.id}>
          <LinkComponent
            to={{ pathname: item.url }}
            target="_blank"
            title={item.title}
          >
            {item.icon}
          </LinkComponent>
        </SocialListItemStyled>
      ))}
    </SocialListStyled>
  );
};

SocialList.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string.isRequired,
      icon: propTypes.node.isRequired,
      url: propTypes.string.isRequired,
      title: propTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default SocialList;
