import { resetDialogState, setUnreadMessages } from 'ducks/dialog';
import {
  amazonCredentialsMapper,
  getFormData,
} from 'ducks/mediaObject/helpers';
import * as mediaObjectServices from 'ducks/mediaObject/services';
import { resetMessageState } from 'ducks/message';
import { connectServerEvents } from 'ducks/serverEvents/slice';
import {
  all,
  call,
  debounce,
  delay,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { MIN_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';
import { routes } from 'constants/routes';
import { serverEventsTypes } from 'constants/serverEventsTypes';

import convertBlobToMP3 from 'tools/convertBlobToMP3';
import redirect from 'tools/redirect';

import { initializeSystemMessages, updateUserPayloadMapper } from './helpers';
import * as services from './services';
import {
  deleteAccountFailure,
  deleteAccountRequest,
  deleteAccountSuccess,
  getAnotherUserFailure,
  getAnotherUserSuccess,
  getCountriesFailure,
  getCountriesRequest,
  getCountriesSuccess,
  getCreatorByIdRequest,
  getCreatorByNicknameRequest,
  getCreatorsBySearchFailure,
  getCreatorsBySearchPaginationResetRequest,
  getCreatorsBySearchPaginationResetSuccess,
  getCreatorsBySearchRequest,
  getCreatorsBySearchSuccess,
  getFanByIdRequest,
  getFanByNicknameRequest,
  getMailingSettingsFailure,
  getMailingSettingsRequest,
  getMailingSettingsSuccess,
  getNewCreatorsBySearchSuccess,
  getTopCreatorsFailure,
  getTopCreatorsRequest,
  getTopCreatorsSuccess,
  getUserFailure,
  getUserRequest,
  getUserSuccess,
  incrementCreatorExplorePage,
  incrementNewCreatorsPage,
  initializeUser,
  logout,
  patchMailingSettingsFailure,
  patchMailingSettingsRequest,
  patchMailingSettingsSuccess,
  restoreAccountFailure,
  restoreAccountRequest,
  restoreAccountSuccess,
  setInitialLoadingToFalse,
  updateUserRequest,
} from './slice';

function* getUserSaga(data) {
  try {
    const token = window.localStorage.getItem('access_token');
    if (token) {
      const user = yield call(services.getUserData);
      yield put(connectServerEvents());
      yield put(resetDialogState());
      yield put(resetMessageState());
      yield put(getUserSuccess(user));
      yield put(setUnreadMessages(user.unreadMessages));
      if (data?.payload?.onSuccess) {
        yield call(data?.payload?.onSuccess, user);
      }
    }
  } catch (error) {
    yield put(getUserFailure());
  }
}

function* initializeUserSaga() {
  try {
    yield call(getUserSaga);
    yield put(setInitialLoadingToFalse());
    yield call(initializeSystemMessages);
  } catch (error) {
    yield put(getUserFailure());
    yield put(setInitialLoadingToFalse());
  }
}

function* updateUserSaga({ payload: { values, onSuccess, file, audioBlob } }) {
  try {
    const user = yield call(
      services.updateUserData,
      updateUserPayloadMapper(values),
    );
    if (file) {
      const [, credentials] = amazonCredentialsMapper(
        user.dataForAvatarPresignedUrl,
        file,
      );
      yield call(mediaObjectServices.amazonMedia, getFormData(credentials));
    }
    if (audioBlob) {
      const audioFile = yield call(
        convertBlobToMP3,
        audioBlob,
        values.personalInfo.welcomeAudioFilename,
      );
      const [, credentials] = amazonCredentialsMapper(
        user.dataForWelcomeAudioPresignedUrl,
        audioFile,
      );
      yield call(mediaObjectServices.amazonMedia, getFormData(credentials));
    }
    yield put(getUserSuccess(user));
    yield call(onSuccess, values);
  } catch (error) {
    yield put(getUserFailure());
  }
}

function* getCountriesSaga() {
  try {
    const countries = yield call(services.getCountries);
    yield put(getCountriesSuccess(countries));
  } catch (error) {
    yield put(getCountriesFailure());
  }
}

function* getCreatorByIdSaga({ payload }) {
  try {
    const user = yield call(services.getCreatorDataById, payload);
    yield put(getAnotherUserSuccess(user));
  } catch (error) {
    yield put(getAnotherUserFailure());
  }
}

function* getFanByIdSaga({ payload }) {
  try {
    const user = yield call(services.getFanDataById, payload);
    yield put(getAnotherUserSuccess(user));
  } catch (error) {
    yield put(getAnotherUserFailure());
  }
}

function* getCreatorByNicknameSaga({
  payload: { nickname, onFail, withDelay = false },
}) {
  try {
    if (withDelay) {
      yield delay(1000);
    }
    const user = yield call(services.getCreatorDataByNickname, nickname);
    yield put(getAnotherUserSuccess(user));
  } catch (error) {
    if (onFail) {
      yield call(onFail);
    }
    yield put(getAnotherUserFailure());
  }
}

function* getFanByNicknameSaga({ payload: { nickname, onFail } }) {
  try {
    const user = yield call(services.getFanDataByNickname, nickname);
    yield put(getAnotherUserSuccess(user));
  } catch (error) {
    yield call(onFail);
    yield put(getAnotherUserFailure());
  }
}

function* getTopCreatorsSaga({ payload }) {
  try {
    const topCreators = yield call(services.getTopCreators, payload);
    yield put(getTopCreatorsSuccess(topCreators));
  } catch (error) {
    yield put(getTopCreatorsFailure());
  }
}

function* getCreatorsBySearchSaga({
  payload: { name, page, sort = 'createdAt', isNewCreators = false },
}) {
  try {
    const creators = yield call(services.getCreatorsBySearch, {
      name,
      page,
      sort,
    });
    if (isNewCreators) {
      yield put(getNewCreatorsBySearchSuccess(creators));
      if (creators.length === MIN_ITEMS_PER_PAGE_VALUE && isNewCreators) {
        yield put(incrementNewCreatorsPage());
      }
    }
    if (!isNewCreators) {
      yield put(getCreatorsBySearchSuccess(creators));
      if (creators.length === MIN_ITEMS_PER_PAGE_VALUE && !isNewCreators) {
        yield put(incrementCreatorExplorePage());
      }
    }
  } catch (error) {
    yield put(getCreatorsBySearchFailure());
  }
}

function* getCreatorsBySearchPaginationResetSaga({
  payload: { name, setSearchNameValue, sort = 'createdAt' },
}) {
  try {
    const creators = yield call(services.getCreatorsBySearch, {
      page: 1,
      name,
      sort,
    });

    if (!creators.length) {
      yield call(setSearchNameValue, '');
      yield put(
        getCreatorsBySearchPaginationResetRequest({
          name: '',
          sort,
        }),
      );
    } else {
      if (creators.length === MIN_ITEMS_PER_PAGE_VALUE) {
        yield put(incrementCreatorExplorePage());
      }
      yield put(getCreatorsBySearchPaginationResetSuccess(creators));
    }
  } catch (error) {
    yield put(getCreatorsBySearchFailure());
  }
}

function* deleteAccountSaga({ payload: onSuccess }) {
  try {
    yield call(services.deleteUserData);
    if (onSuccess) {
      yield call(onSuccess);
    }
    yield put(deleteAccountSuccess());
    yield put(getUserRequest());
  } catch (error) {
    yield put(deleteAccountFailure());
  }
}

function* restoreAccountSaga({ payload: { onSuccess } }) {
  try {
    yield call(services.restoreUserData);
    yield call(onSuccess);
    yield put(restoreAccountSuccess());
    yield put(getUserRequest());
  } catch (error) {
    yield put(restoreAccountFailure());
  }
}

function* getMailingSettingsSaga() {
  try {
    const mailingSettings = yield call(services.getMailingSettings);
    yield put(getMailingSettingsSuccess(mailingSettings));
  } catch (error) {
    yield put(getMailingSettingsFailure());
  }
}

function* patchMailingSettingsSaga({ payload: { id, body, onSuccess } }) {
  try {
    const mailingSettings = yield call(services.patchMailingSettings, {
      id,
      body,
    });
    yield put(patchMailingSettingsSuccess(mailingSettings));
    yield call(onSuccess);
  } catch (error) {
    yield put(patchMailingSettingsFailure());
  }
}

function* logoutSaga() {
  yield call(redirect, routes.LOGIN);
  window.localStorage.removeItem('refresh_token');
}

function* emailVerifiedSaga() {
  yield put(getUserRequest());
}

export default function* watchUser() {
  yield all([
    takeLatest(logout, logoutSaga),
    takeLatest(getUserRequest, getUserSaga),
    takeLatest(updateUserRequest, updateUserSaga),
    takeLatest(initializeUser, initializeUserSaga),
    takeLatest(getCountriesRequest, getCountriesSaga),
    takeLatest(getCreatorByIdRequest, getCreatorByIdSaga),
    takeLatest(getFanByIdRequest, getFanByIdSaga),
    takeLatest(getCreatorByNicknameRequest, getCreatorByNicknameSaga),
    takeLatest(getFanByNicknameRequest, getFanByNicknameSaga),
    takeLatest(getTopCreatorsRequest, getTopCreatorsSaga),
    takeLatest(deleteAccountRequest, deleteAccountSaga),
    takeLatest(restoreAccountRequest, restoreAccountSaga),
    takeLatest(getCreatorsBySearchRequest, getCreatorsBySearchSaga),
    takeLatest(getMailingSettingsRequest, getMailingSettingsSaga),
    takeLatest(patchMailingSettingsRequest, patchMailingSettingsSaga),
    takeLatest(serverEventsTypes.VERIFIED_EMAIL, emailVerifiedSaga),
    debounce(
      300,
      getCreatorsBySearchPaginationResetRequest,
      getCreatorsBySearchPaginationResetSaga,
    ),
  ]);
}
