/* eslint-disable camelcase */
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import size from 'lodash/size';

import { revenueFormat } from 'constants/dateFormatters';
import { fanPrices } from 'constants/fanPrices';
import socialNetworksNames from 'constants/socialNetworksNames';
import { systemMessagesNames } from 'constants/systemMessagesNames';

export const registerPayloadMapper = (payload, isSettings = false) => {
  const newPayload = omitBy(
    payload,
    (value, name) =>
      name === 'confirmPassword' ||
      name === 'consentCheckbox' ||
      name === 'company' ||
      name === 'formRefreshField' ||
      name === 'avatarField' ||
      name === 'confirmNewPassword' ||
      name === 'certificate' ||
      name === 'Country' ||
      isEmpty(value),
  );

  const socialNetworks = [];

  const {
    instagram,
    snapchat,
    tiktok,
    youtube,
    twitter,
    facebook,
    spotify,
    premium,
    country,
    role,
    bio,
    phone,
    ...rest
  } = newPayload;

  if (instagram) {
    socialNetworks.push({
      name: socialNetworksNames.INSTAGRAM,
      nickname: instagram,
      subscribers: '10000', // temporary
    });
  }
  if (snapchat) {
    socialNetworks.push({
      name: socialNetworksNames.SNAPCHAT,
      nickname: snapchat,
      subscribers: '10000',
    });
  }
  if (tiktok) {
    socialNetworks.push({
      name: socialNetworksNames.TIKTOK,
      nickname: tiktok,
      subscribers: '10000',
    });
  }
  if (youtube) {
    socialNetworks.push({
      name: socialNetworksNames.YOUTUBE,
      nickname: youtube,
      subscribers: '10000',
    });
  }
  if (twitter) {
    socialNetworks.push({
      name: socialNetworksNames.TWITTER,
      nickname: twitter,
      subscribers: '10000',
    });
  }
  if (facebook) {
    socialNetworks.push({
      name: socialNetworksNames.FACEBOOK,
      nickname: facebook,
      subscribers: '10000',
    });
  }
  if (spotify) {
    socialNetworks.push({
      name: socialNetworksNames.SPOTIFY,
      nickname: spotify,
      subscribers: '10000',
    });
  }

  const newBody = {
    ...rest,
    ...(size(payload.company) === 5 && { company: payload.company }),
    ...(payload.company === null && { company: null }),
    ...(role && { role }),
    ...(country && { country }),
    ...(!bio && isSettings && { bio: '' }),
    ...(bio && { bio }),
    ...(phone && { phone: phone.replace(/[^\d]/g, '') }),
  };

  if (payload.specificInfo === null) {
    newBody.specificInfo = payload.specificInfo;
  }
  if (payload.bio === null) {
    newBody.bio = payload.bio;
  }
  if (payload.city === null) {
    newBody.city = payload.city;
  }
  if (payload.accountOptions) {
    newBody.accountOptions = payload.accountOptions;
  }
  if (payload.paymentInfo) {
    newBody.paymentInfo = payload.paymentInfo;
  }
  if (payload.dateOfBirth) {
    newBody.dateOfBirth = format(new Date(payload.dateOfBirth), 'Y-MM-dd');
  }
  if (instagram || snapchat || tiktok || youtube || twitter) {
    newBody.socialNetworks = [
      {
        name: socialNetworksNames.PREMIUM,
        nickname: premium.nickname,
        subscribers: premium.subscribers,
      },
      ...socialNetworks,
    ];
  }

  return newBody;
};

export const registerPartTwoPayloadMapper = payload => {
  const newPayload = payload;
  const {
    paidProfileSwitch,
    messageSwitch,
    personalizedVideoSwitch,
    shareFeatureSwitch,

    monthlyPaymentRange,
    profileIsVisibleInShareFeatureSwitch,
    previewMediaObjectId,
    basic,
    silver,
    gold,
    automaticMediaSendText,

    guaranteedResponseSwitch,
    guaranteedResponsePriceRange,
    videoResponseSwitch,
    videoResponsePriceRange,
    guaranteedPhotoSwitch,
    privateMediaPriceRange,

    personalizedVideoPriceRange,
    addProfileVideoSwitch,
    videoMediaObjectId,

    virementSwitch,
    paymentName,
    iban,
    bic,
    payPalSwitch,
    paypalEmail,

    avatarField,
    PRIVATE_PROFILE,
    SHARE,

    ...rest
  } = newPayload;

  const result = {
    ...rest,
  };

  const accountOptions = {};

  if (paidProfileSwitch) {
    const privateProfile = {
      PRICE_PRIVATE_PROFILE: fanPrices[monthlyPaymentRange].monthly,
      AUTOMATIC_MEDIA_SEND: [],
      PROFILE_VISIBLE: true,
      PREVIEW_MEDIA_OBJECT_ID: null,
      AUTOMATIC_MEDIA_SEND_TEXT: null,
    };
    accountOptions.PRIVATE_PROFILE = privateProfile;
  }

  if (messageSwitch) {
    const messages = {
      PRICE_GUARANTEED_RESPONSE: guaranteedResponseSwitch
        ? guaranteedResponsePriceRange[0]
        : 0,
      PRICE_VIDEO_RESPONSE:
        videoResponseSwitch && (paidProfileSwitch || PRIVATE_PROFILE)
          ? videoResponsePriceRange[0]
          : 0,
      PRICE_PRIVATE_MEDIA: guaranteedPhotoSwitch
        ? privateMediaPriceRange[0]
        : 0,
    };
    accountOptions.MESSAGES = messages;
  }
  if (personalizedVideoSwitch) {
    const personalizedVideos = {
      PRICE_PERSONALIZED_VIDEO: personalizedVideoPriceRange[0],
      VIDEO_MEDIA_OBJECT_ID: addProfileVideoSwitch ? videoMediaObjectId : null,
    };
    accountOptions.PERSONALIZED_VIDEOS = personalizedVideos;
  }
  if (
    paidProfileSwitch ||
    messageSwitch ||
    personalizedVideoSwitch ||
    shareFeatureSwitch
  ) {
    if (typeof shareFeatureSwitch === 'boolean') {
      accountOptions.SHARE = !!shareFeatureSwitch;
    } else {
      accountOptions.SHARE = !!SHARE;
    }
    if (typeof paidProfileSwitch !== 'boolean' && PRIVATE_PROFILE) {
      accountOptions.PRIVATE_PROFILE = PRIVATE_PROFILE;
    }
    result.accountOptions = accountOptions;
  }

  if (virementSwitch || payPalSwitch) {
    const paymentInfo = {};

    if (virementSwitch) {
      paymentInfo.type = 'transfer';
      paymentInfo.name = paymentName;
      paymentInfo.iban = iban;
      paymentInfo.bic = bic;
    }
    if (payPalSwitch) {
      paymentInfo.type = 'paypal';
      paymentInfo.paypalEmail = paypalEmail;
    }

    result.paymentInfo = paymentInfo;
  }

  if (avatarField && !avatarField.includes('strike')) {
    result.avatar = avatarField;
  }

  return result;
};

export const initializeSystemMessages = () => {
  if (!window.localStorage.getItem('system_messages_to_show')) {
    window.localStorage.setItem(
      'system_messages_to_show',
      JSON.stringify(
        Object.values(systemMessagesNames).reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {}),
      ),
    );
  }
};

export const updateUserPayloadMapper = payload => {
  const result = payload;
  if (result?.personalInfo?.dateOfBirth) {
    result.personalInfo.dateOfBirth = revenueFormat(
      result.personalInfo.dateOfBirth,
    );
  }
  return result;
};
