import AddPaymentMethodFormContainer from 'containers/payment/AddPaymentMethodFormContainer';
import propTypes from 'prop-types';

import { BaseModal } from './BaseModal';

const NewPaymentCardModal = ({
  isOpen,
  setOpen,
  stripePromise,
  onAddPaymentSuccess,
  ...props
}) => {
  return (
    <BaseModal
      isOpen={isOpen}
      setOpen={setOpen}
      title="Nouvelle carte"
      closeButton
      {...props}
    >
      <AddPaymentMethodFormContainer
        stripePromise={stripePromise}
        setModalOpen={setOpen}
        onAddPaymentSuccess={onAddPaymentSuccess}
      />
    </BaseModal>
  );
};

NewPaymentCardModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
  stripePromise: propTypes.instanceOf(Promise),
  onAddPaymentSuccess: propTypes.func,
};

export default NewPaymentCardModal;
