import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { loadStripe } from '@stripe/stripe-js';
import { GOOGLE_APP_KEY, STRIPE_PUBLIC_KEY } from 'config';
import { ThemeProvider } from 'styled-components';

import { Toast } from 'components/toast';

import { theme } from 'styles/Theme';

import App from './App';
import './i18n';
import { history, store } from './store';
import GlobalStyle from './styles/GlobalStyle';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <>
    <GlobalStyle />
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_APP_KEY}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <App stripePromise={stripePromise} />
          </Router>
        </ThemeProvider>
        <Toast />
      </GoogleOAuthProvider>
    </Provider>
  </>,
);
