import propTypes from 'prop-types';

import { MButton } from 'components/buttons';
import { MBox } from 'components/common';
import { CardRadio } from 'components/forms';
import { ErrorNotification } from 'components/notifications';
import { Heading4 } from 'components/texts';

const PaymentMethodTemplate = ({
  isFullListCards,
  handleAddPaymentMethodButtonClick,
  paymentMethods,
  patchPaymentMethod,
  openDeletePaymentMethodModal,
}) => {
  return (
    <MBox mt="lg">
      <Heading4 id="paymentMethodList" as="h2" mb="lg">
        Vos moyens de paiment
      </Heading4>
      <CardRadio
        name="my-cards"
        items={paymentMethods}
        ariaLabelledby="paymentMethodList"
        isApplePay
        isGooglePay
        patchPaymentMethod={patchPaymentMethod}
        openDeletePaymentMethodModal={openDeletePaymentMethodModal}
      />
      {isFullListCards ? (
        <ErrorNotification
          text={
            <>
              Vous ne pouvez pas avoir plus de 5 cartes.
              <br /> Supprimez en pour en ajouter d’autres.
            </>
          }
        />
      ) : (
        // Open modal NewPaymentCardModal
        <MButton
          onClick={handleAddPaymentMethodButtonClick}
          width="100%"
          secondary
          scale="sm"
        >
          Ajouter une carte
        </MButton>
      )}
    </MBox>
  );
};

PaymentMethodTemplate.propTypes = {
  isFullListCards: propTypes.bool,
  handleAddPaymentMethodButtonClick: propTypes.func,
  patchPaymentMethod: propTypes.func,
  openDeletePaymentMethodModal: propTypes.func,
};

export default PaymentMethodTemplate;
