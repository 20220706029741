import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  hideModal,
  selectModalIsOpen,
  selectModalProps,
  selectModalType,
  setModalOpenState,
} from 'ducks/modal';

import { modalNames } from 'constants/modalNames';

import {
  ErrorPaymentModal,
  ImageFullModal,
  MessageIsStillLoadingModal,
  NewPaymentCardModal,
  VideoFullModal,
  VideoRecordModal,
} from 'components/modals';
import AvatarCropperModal from 'components/modals/AvatarCropperModal';
import BoostMessageModal from 'components/modals/BoostMessageModal';
import ConfirmationDeleteAccountCreatorModal from 'components/modals/ConfirmationDeleteAccountCreator';
import ConfirmationDeleteAccountFanModal from 'components/modals/ConfirmationDeleteAccountFan';
import ConfirmationDeleteCardModal from 'components/modals/ConfirmationDeleteCardModal';
import CreatorProfileShareModal from 'components/modals/CreatorProfileShareModal';
import NewConversationCreatorModal from 'components/modals/NewConversationCreatorModal';
import NewConversationFanModal from 'components/modals/NewConversationFanModal';

const MODAL_COMPONENTS = {
  [modalNames.SHARE]: CreatorProfileShareModal,
  [modalNames.CROPPER]: AvatarCropperModal,
  [modalNames.VIDEO_FULL]: VideoFullModal,
  [modalNames.IMAGE_FULL]: ImageFullModal,
  [modalNames.NEW_CONVERSATION_FAN]: NewConversationFanModal,
  [modalNames.NEW_CONVERSATION_CREATOR]: NewConversationCreatorModal,
  [modalNames.BOOST_MESSAGE]: BoostMessageModal,
  [modalNames.VIDEO_RECORD]: VideoRecordModal,
  [modalNames.ADD_PAYMENT_METHOD]: NewPaymentCardModal,
  [modalNames.DELETE_PAYMENT_METHOD]: ConfirmationDeleteCardModal,
  [modalNames.DECLINED_PAYMENT]: ErrorPaymentModal,
  [modalNames.DELETE_ACCOUNT_CREATOR]: ConfirmationDeleteAccountCreatorModal,
  [modalNames.DELETE_ACCOUNT_FAN]: ConfirmationDeleteAccountFanModal,
  [modalNames.MESSAGE_IS_STILL_LOADING]: MessageIsStillLoadingModal,
};

const ModalRoot = () => {
  const modalType = useSelector(selectModalType);
  const { onClose, ...modalProps } = useSelector(selectModalProps);
  const modalIsOpen = useSelector(selectModalIsOpen);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClose = useCallback(() => {
    dispatch(hideModal());
    onClose && onClose();
  }, [dispatch, onClose]);
  const setOpen = useCallback(
    state => dispatch(setModalOpenState(state)),
    [dispatch],
  );
  const SpecificModal = MODAL_COMPONENTS[modalType];
  /* useRegistrationModals(); */
  useEffect(() => {
    if (
      modalType &&
      modalType !== modalNames.WELCOME &&
      modalType !== modalNames.BRAVO_CERTIFICATION &&
      modalType !== modalNames.PAYMENTS_SUCCESS &&
      modalType !== modalNames.PAYMENTS_PROBLEM &&
      modalType !== modalNames.EMAIL_UNSUBSCRIBE
    ) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {!!SpecificModal && (
        <SpecificModal
          isOpen={modalIsOpen}
          setOpen={setOpen}
          onClose={handleClose}
          {...modalProps}
        />
      )}
    </>
  );
};

export default ModalRoot;
