import { createSelector } from 'reselect';

import { paymentMethodFormat } from 'constants/dateFormatters';

export const selectPaymentMethodsCollection = state =>
  state.paymentMethods.paymentMethodsCollection;

export const selectPaymentMethodsPage = state =>
  state.paymentMethods.paymentMethodsPage;

export const selectPaymentMethod = state => state.paymentMethods.paymentMethod;

export const selectPatchedPaymentMethod = state =>
  state.paymentMethods.patchedPaymentMethod;

export const selectStripeToken = state => state.paymentMethods.stripeToken;

export const selectPaymentMethodsCollectionPrepared = createSelector(
  selectPaymentMethodsCollection,
  paymentMethodsCollection => {
    if (paymentMethodsCollection.length) {
      return paymentMethodsCollection.map((method, index) => ({
        id: method.id,
        brand: method.brand,
        cardholderName: method.cardholderName,
        default: method.default,
        lastFourDigits: method.lastFourDigits,
        expiresAt: paymentMethodFormat(new Date(method.expiresAt)),
        value: `card-${index}`,
        applePay: method.brand === 'apple_pay',
        googlePay: method.brand === 'google_pay',
      }));
    }
    return [];
  },
);
