import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import {
  clearShareDetails,
  createShareRequest,
  selectShareDetails,
} from 'ducks/share';
import {
  clearAnotherUserData,
  getCreatorByIdRequest,
  selectIsUserLoading,
  selectLoadedUser,
} from 'ducks/user';
import { setClipboard } from 'helpers/copy';
import CreatorPublicProfileTemplate from 'pages/templates/CreatorPublicProfileTemplate';
import propTypes from 'prop-types';

import { routes } from 'constants/routes';

import getQueryParams from 'tools/getQueryParams';
import { formatPrice } from 'tools/stringFormatter';

import { MButtonShare } from 'components/buttons';
import { LoadingSpinner, MBox } from 'components/common';
import { IconCoins, IconPercent } from 'components/icons';
import { Text } from 'components/texts';

import { gradients, theme } from 'styles/Theme';

import { BaseModal } from './BaseModal';

const CreatorProfileShareModal = ({ user, setOpen, ...props }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsUserLoading);
  const shareDetails = useSelector(selectShareDetails);
  const fetchedUser = useSelector(selectLoadedUser);

  useEffect(() => {
    dispatch(getCreatorByIdRequest(user.creatorId));
  }, [dispatch, user.creatorId]);

  const [isActive, setActive] = useState(false);

  const createShare = useCallback(() => {
    if (fetchedUser) {
      dispatch(createShareRequest(fetchedUser.id));
    }
  }, [dispatch, fetchedUser]);

  const updateClipboard = useCallback(() => {
    if (user?.username) {
      setClipboard(
        `${window.location.origin}${
          generatePath(routes.PREMIUM_PROFILE, {
            nickname: user?.username.toLocaleLowerCase(),
          }) + getQueryParams({ shareCode: shareDetails?.urlCode })
        }`,
      );
    }
    setActive(true);
  }, [shareDetails?.urlCode, user?.username]);

  useEffect(() => {
    if (shareDetails?.urlCode) {
      updateClipboard();
    }
  }, [updateClipboard, shareDetails?.urlCode]);

  const clearShare = useCallback(
    onClose => {
      dispatch(clearShareDetails());
      dispatch(clearAnotherUserData());
      onClose();
    },
    [dispatch],
  );

  return (
    <BaseModal
      setOpen={setOpen}
      closeButton
      onGoBackClick={clearShare}
      title="Premuim Share"
      {...props}
    >
      {!fetchedUser ? (
        <LoadingSpinner position="relative" />
      ) : (
        <MBox display="flex" alignItems="center" flexDirection="column">
          <CreatorPublicProfileTemplate isModal user={fetchedUser} />
          <MBox
            display="flex"
            width="100%"
            mt="primaryLg"
            mb="lg"
            gap={theme.space.sm}
            justifyContent="space-between"
          >
            <MBox bg="grayCloud" flex="1" borderRadius="sm" p="primaryMd">
              <MBox
                display="flex"
                borderRadius="circle"
                background={gradients.secondary}
                width="4rem"
                height="4rem"
                alignItems="center"
                justifyContent="center"
                mx="auto"
              >
                <IconPercent palette={theme.colors.white} />
              </MBox>
              <Text mt="md" type="label2" lineHeight="md">
                Vous gagnerez{' '}
                <Text as="span" type="subtitle2" $isGradient>
                  20% des revenus générés
                </Text>{' '}
                par votre communauté
              </Text>
            </MBox>
            <MBox bg="grayCloud" flex="1" borderRadius="sm" p="primaryMd">
              <MBox
                display="flex"
                borderRadius="circle"
                background={gradients.secondary}
                width="4rem"
                height="4rem"
                alignItems="center"
                justifyContent="center"
                mx="auto"
              >
                <IconCoins palette={theme.colors.white} />
              </MBox>
              <Text mt="md" type="label2" lineHeight="md">
                Ce créateur vous a déjà rapporté{' '}
                <Text as="span" type="subtitle1" $isGradient>
                  {formatPrice(fetchedUser?.sharesTotalEarnings, true)}
                </Text>
              </Text>
            </MBox>
          </MBox>
          <MButtonShare
            active={isActive}
            onClick={createShare}
            activeText="Lien copié"
            text="Copier le lien de partage"
            scale="sm"
            width="100%"
            loader={isLoading}
            primary
          />
        </MBox>
      )}
    </BaseModal>
  );
};

CreatorProfileShareModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  user: propTypes.object.isRequired,
};

export default CreatorProfileShareModal;
