import { useCallback, useState } from 'react';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';
import propTypes from 'prop-types';
import styled from 'styled-components';

import getRoundedCanvas from 'tools/getRoundedCanvas';

import { MButton } from 'components/buttons';
import { MBox } from 'components/common';
import BaseModal from 'components/modals/BaseModal';

import { theme } from 'styles/Theme';

const StyledCropper = styled(Cropper)`
  width: 100%;
  max-height: ${theme.sizes.cropperModalContentHeight};

  &.round-cropper {
    .cropper-view-box {
      border-radius: ${theme.radii.circle};
    }
  }

  .cropper-container {
    min-width: 100%;
    background-color: ${theme.colors.black};
  }

  .cropper-view-box {
    border: 5px solid ${theme.colors.primary};
    outline: none;
  }
`;

const AvatarCropperModal = ({
  isRoundCropper,
  setOpen,
  avatar,
  className,
  onCropSuccess,
  onGoBackClick,
  withModalClose,
  isFeed,
  fileName,
  ...props
}) => {
  const [cropper, setCropper] = useState();

  const getCropData = useCallback(() => {
    const croppedCanvas = cropper.getCroppedCanvas({
      fillColor: theme.colors.black,
    });
    const roundedCanvas = getRoundedCanvas(croppedCanvas);

    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

    const file = dataURLtoFile(croppedCanvas.toDataURL(), fileName);

    if (typeof cropper !== 'undefined') {
      if (isRoundCropper) {
        onCropSuccess(
          roundedCanvas.toDataURL(),
          dataURLtoFile(roundedCanvas.toDataURL(), fileName),
        );
      } else {
        onCropSuccess(file);
      }
      if (withModalClose) {
        setOpen(false);
      }
    }
  }, [
    cropper,
    fileName,
    isRoundCropper,
    onCropSuccess,
    setOpen,
    withModalClose,
  ]);

  return (
    <BaseModal
      setOpen={setOpen}
      hasBackButton
      isCropper
      button={
        <MButton onClick={getCropData} primary width="100%">
          Suivant
        </MButton>
      }
      onGoBackClick={onGoBackClick}
      {...props}
    >
      <MBox
        mx={`-${theme.space.primaryMd}`}
        mt={`-${theme.space.lg}`}
        minHeight="0"
        flexDirection="column"
      >
        <StyledCropper
          className={className}
          zoomTo={0.1}
          initialAspectRatio={1}
          aspectRatio={1}
          src={avatar}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          minContainerHeight={300}
          minContainerWidth={300}
          autoCropArea={1}
          background={false}
          guides={false}
          checkOrientation={false}
          cropBoxResizable={false}
          cropBoxMovable={false}
          toggleDragModeOnDblclick={false}
          dragMode="move"
          highlight={false}
          center={false}
          onInitialized={instance => {
            setCropper(instance);
          }}
        />
      </MBox>
    </BaseModal>
  );
};

AvatarCropperModal.defaultProps = {
  isRoundCropper: true,
  withModalClose: true,
};

AvatarCropperModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  avatar: propTypes.string,
  className: propTypes.string,
  isRoundCropper: propTypes.bool,
  onCropSuccess: propTypes.func.isRequired,
  onGoBackClick: propTypes.func,
  withModalClose: propTypes.bool,
  fileName: propTypes.string,
};

export default AvatarCropperModal;
