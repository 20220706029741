import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { hideModal } from 'ducks/modal';
import propTypes from 'prop-types';

import { routes } from 'constants/routes';

import { MButton } from 'components/buttons';
import { IconWarning } from 'components/icons';
import { Text } from 'components/texts';

import { BaseModal } from './BaseModal';

const MessageIsStillLoadingModal = ({
  isOpen,
  setOpen,
  onPageExit,
  ...props
}) => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  return (
    <BaseModal
      isOpen={isOpen}
      setOpen={setOpen}
      title="Message en cours d'envoi"
      titleColor="error"
      isCentered
      {...props}
    >
      <IconWarning size="48" mb="md" />
      <Text
        type="label1"
        textAlign="center"
        mb="lg"
        id="ariaModalDesc"
        lineHeight="md"
      >
        Votre message est en cours d&apos;envoi dans le chat. Si vous quittez
        cette page maintenant il ne sera pas envoyé.
      </Text>
      <MButton
        as={Link}
        to={routes.MESSAGES}
        onClick={() => {
          onPageExit();
          dispatch(hideModal());
        }}
        type={null}
        scale="sm"
        width="100%"
        mb="sm"
        secondary
      >
        Quittez la page
      </MButton>
      <MButton scale="sm" primary width="100%" onClick={onClose}>
        Annuler
      </MButton>
    </BaseModal>
  );
};

MessageIsStillLoadingModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
  onPageExit: propTypes.func,
};

export default MessageIsStillLoadingModal;
