import { useCallback, useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { routes } from 'constants/routes';

import { Avatar } from 'components/avatar';
import { ButtonCircle } from 'components/buttons';
import { IconShare } from 'components/icons';
import { Text } from 'components/texts';

import { MCardLinkStyles, MCardStyles, TextOverflow } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

const ShareCardStyled = styled.article`
  max-width: 100%;
  ${props => MCardStyles({ twoColumns: props.twoColumns })}
`;

const ShareCardWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ShareCardNameStyled = styled.h3`
  color: ${theme.colors.black};
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.medium};
  line-height: ${theme.lineHeights.md};
  ${TextOverflow};
`;

const ShareCardLinkStyled = styled(Link)`
  color: ${theme.colors.black};
  ${MCardLinkStyles('0')};
  ${props =>
    !props.isUserValidated &&
    `
    &:before {
      width: 90%;
    }
  `};
`;

const ShareCard = ({
  item,
  withLink,
  isUserValidated,
  twoColumns,
  openShareModal,
}) => {
  const handleShareButtonClick = useCallback(
    () => openShareModal(item),
    [item, openShareModal],
  );

  const generatedPathToChat = useMemo(
    () =>
      item?.id
        ? generatePath(routes.MESSENGER_NEW_DIALOG, {
            id: item.id,
          })
        : null,
    [item?.id],
  );

  const generatedPathToProfile = useMemo(
    () =>
      item.username
        ? {
            pathname: generatePath(routes.PREMIUM_PROFILE, {
              nickname: item.username,
            }),
            state: {
              fromSharePage: true,
            },
          }
        : null,
    [item.username],
  );

  return (
    <ShareCardStyled twoColumns={twoColumns}>
      <Avatar
        $src={item?.avatar?.squarePreviewUrl}
        $srcSetAvif={item?.avatar?.squarePreviewAvifUrl}
        $srcSetWebp={item?.avatar?.squarePreviewWebpUrl}
        isChecked={item.certified}
      />
      <ShareCardWrapperStyled>
        <ShareCardNameStyled>
          <ShareCardLinkStyled
            isUserValidated={isUserValidated}
            to={withLink ? generatedPathToChat : generatedPathToProfile}
            title={item.publicName}
          >
            {item.publicName}
          </ShareCardLinkStyled>
        </ShareCardNameStyled>
        <Text
          type="body3"
          color="grayMedium"
          lineHeight="sm"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {item.category}
        </Text>
      </ShareCardWrapperStyled>
      {!twoColumns && (
        <ButtonCircle
          scale="xs"
          aria-label="Modal de partage ouvert"
          onClick={handleShareButtonClick}
          disabled={!isUserValidated}
        >
          <IconShare />
        </ButtonCircle>
      )}
    </ShareCardStyled>
  );
};

ShareCard.defaultProps = {
  withLink: false,
};

ShareCard.propTypes = {
  item: propTypes.shape({
    publicName: propTypes.string,
    avatarcertifiedChecked: propTypes.bool,
    category: propTypes.string,
    avatarUrl: propTypes.string,
    handleShareButtonClick: propTypes.func,
    link: propTypes.string,
  }),
  twoColumns: propTypes.bool,
  openShareModal: propTypes.func,
  withLink: propTypes.bool,
  isUserValidated: propTypes.bool,
};

export default ShareCard;
