import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { deleteAccountRequest } from 'ducks/user';
import propTypes from 'prop-types';

import { MButton } from 'components/buttons';
import { Text } from 'components/texts';

import { BaseModal } from './BaseModal';

const ConfirmationDeleteAccountCreatorModal = ({
  isOpen,
  setOpen,
  ...props
}) => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const deleteAccountHandler = useCallback(() => {
    dispatch(deleteAccountRequest());
    onClose();
  }, [dispatch, onClose]);

  return (
    <BaseModal
      isOpen={isOpen}
      setOpen={setOpen}
      title="Supprimer votre compte ?"
      isCentered
      {...props}
    >
      <Text
        type="label1"
        textAlign="center"
        mb="lg"
        id="ariaModalDesc"
        lineHeight="md"
      >
        Êtes vous certain de vouloir supprimer vote compte ? Cette action est
        irréversible et toutes vos données seront supprimées.
        <br />
        <br />
        Votre compte sera supprimé manuellement par nos équipes.
      </Text>
      <MButton
        scale="sm"
        width="100%"
        mb="sm"
        tertiary
        onClick={deleteAccountHandler}
      >
        Supprimer mon compte
      </MButton>
      <MButton scale="sm" primary width="100%" onClick={onClose}>
        Annuler
      </MButton>
    </BaseModal>
  );
};

ConfirmationDeleteAccountCreatorModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
};

export default ConfirmationDeleteAccountCreatorModal;
