import { useMemo } from 'react';

import propTypes from 'prop-types';

import { LoadingSpinner, MBox } from 'components/common';
import InfiniteScroller from 'components/scroller/InfiniteScroller';
import { Text } from 'components/texts';

import { breakpoints } from 'styles/Theme';

import ShareCard from './ShareCard';

const ShareList = ({
  creators,
  loadMoreCreators,
  hasMoreCreators,
  openShareModal,
  isShareListLoading,
  searchNameValue,
  isUserValidated,
  ...props
}) => {
  const renderShareList = useMemo(() => {
    if (creators?.length !== 0) {
      return (
        <>
          {searchNameValue === '' && (
            <Text as="h2" type="subtitle2" lineHeight="md" mb="sm">
              Suggestions
            </Text>
          )}

          <InfiniteScroller
            loadMore={loadMoreCreators}
            hasMore={hasMoreCreators}
          >
            {creators.map(item => (
              <ShareCard
                key={item?.id}
                item={item}
                openShareModal={openShareModal}
                isUserValidated={isUserValidated}
                {...props}
              />
            ))}
          </InfiniteScroller>
        </>
      );
    }
    return <LoadingSpinner position="relative" />;
  }, [
    isUserValidated,
    creators,
    searchNameValue,
    loadMoreCreators,
    hasMoreCreators,
    openShareModal,
    props,
  ]);

  return (
    <>
      <MBox mt="primaryMd" maxWidth={breakpoints.md} mx="auto">
        {renderShareList}
      </MBox>
    </>
  );
};

ShareList.defaultProps = {
  isUserValidated: true,
};

ShareList.propTypes = {
  loadMoreCreators: propTypes.func,
  hasMoreCreators: propTypes.bool,
  creators: propTypes.arrayOf(propTypes.object),
  openShareModal: propTypes.func,
  isShareListLoading: propTypes.bool,
  searchNameValue: propTypes.string,
  isUserValidated: propTypes.bool,
};

export default ShareList;
