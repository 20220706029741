import WaveSurferPlayerContainer from 'containers/voiceMessage/WaveSurferPlayerContainer';
import propTypes from 'prop-types';

const VoiceMessage = ({ audioUrl, currentVoice, isMessage, messageId }) => {
  return (
    <WaveSurferPlayerContainer
      currentVoice={currentVoice}
      isMessage={isMessage}
      url={audioUrl}
      messageId={messageId}
    />
  );
};

VoiceMessage.defaultProps = {
  isMessage: false,
};

VoiceMessage.propTypes = {
  audioUrl: propTypes.string.isRequired,
  currentVoice: propTypes.bool,
  isMessage: propTypes.bool,
  messageId: propTypes.string,
};

export default VoiceMessage;
