export const systemMessagesNames = {
  CREATOR_PRIVATE_MEDIA_REFUSE: 'CREATOR_PRIVATE_MEDIA_REFUSE',
  CREATOR_BRAVO: 'CREATOR_BRAVO',
  FAN_GUARANTEED_VIDEO_RESPONSE_PAID: 'FAN_GUARANTEED_VIDEO_RESPONSE_PAID',
  FAN_GUARANTEED_RESPONSE_PAID: 'FAN_GUARANTEED_RESPONSE_PAID',
  CREATOR_DONT_RESPONSE_IN_TIME: 'CREATOR_DONT_RESPONSE_IN_TIME',
  FAN_REFUSED_PRICE_PROPOSAL: 'FAN_REFUSED_PRICE_PROPOSAL',
  CREATOR_REQUEST_ANSWER_IN_2_DAYS: 'CREATOR_REQUEST_ANSWER_IN_2_DAYS',
  CREATOR_REQUEST_ANSWER_IN_7_DAYS: 'CREATOR_REQUEST_ANSWER_IN_7_DAYS',
  CREATOR_FAN_PAID_FOR_GUARANTEED_RESPONSE:
    'CREATOR_FAN_PAID_FOR_GUARANTEED_RESPONSE',
  CREATOR_FAN_PAID_FOR_GUARANTEED_VIDEO:
    'CREATOR_FAN_PAID_FOR_GUARANTEED_VIDEO',
};
