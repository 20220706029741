const scrollToBottom = () => {
  const infiniteScroll = document.querySelector('#infiniteScroll');
  if (infiniteScroll) {
    const infiniteScrollHeight = infiniteScroll.scrollHeight;
    infiniteScroll.scrollTo({
      top: infiniteScrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export default scrollToBottom;
