import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectIsFileLoading } from 'ducks/mediaObject';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { CloseFullButton } from 'components/buttons';
import { LoadingSpinner, MBox } from 'components/common';
import ReactVideoPlayer from 'components/common/ReactVideoPlayer';
import { IconDownload } from 'components/icons';

import { VisuallyHidden } from 'styles/MixinStyle';
import { breakpoints, theme } from 'styles/Theme';

import { BaseModal } from './BaseModal';

const DownloadButtonStyled = styled.button`
  position: absolute;
  top: ${theme.space.xl};
  right: 8%;
`;

const VideoFullModal = ({
  setOpen,
  onDownloadVideo,
  $src,
  isAutoPlay,
  ...props
}) => {
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const isLoading = useSelector(selectIsFileLoading);
  return (
    <BaseModal
      setOpen={setOpen}
      isCentered
      isFullMedia
      width={theme.sizes.mediaFullModalWidth}
      maxWidth={breakpoints.md}
      isBackgroundTransparent
      {...props}
    >
      <VisuallyHidden as="h2" id="ariaModalTitle">
        Modal with full video
      </VisuallyHidden>
      <MBox position="relative">
        {$src && <ReactVideoPlayer src={$src} isAutoPlay={isAutoPlay} />}
        <DownloadButtonStyled
          aria-label="Télécharger l'image"
          type="button"
          onClick={onDownloadVideo}
          disabled={isLoading}
        >
          {isLoading ? (
            <LoadingSpinner position="relative" size="36" />
          ) : (
            <IconDownload />
          )}
        </DownloadButtonStyled>
      </MBox>
      <MBox display="flex" justifyContent="center">
        <CloseFullButton onClick={onClose} />
      </MBox>
    </BaseModal>
  );
};

VideoFullModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  $src: propTypes.string,
  isAutoPlay: propTypes.bool,
  onDownloadVideo: propTypes.func.isRequired,
  isLoading: propTypes.bool,
};

export default VideoFullModal;
