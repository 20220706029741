import { useCallback, useState } from 'react';
import ReactPlayer from 'react-player';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { IconPlay } from 'components/icons';

import { theme } from 'styles/Theme';

import { MBox } from './MBox';

const VideoPlayerWrapperStyled = styled.div`
  position: relative;
  .react-player {
    overflow: hidden;
    border-radius: ${theme.radii.sm};
    max-width: 90%;
    margin: 0 auto;
    background: ${theme.colors.black};
    video {
      display: block;
      max-height: calc(95vh - 4.8rem);
      border-radius: ${theme.radii.sm};
    }
  }
`;

const ReactVideoPlayer = ({ src, isAutoPlay, ...props }) => {
  const [isPlaying, setPlaying] = useState(isAutoPlay);
  const [isPlayButton, setIsPlayButton] = useState(!isAutoPlay);
  const playButtonHandler = useCallback(() => {
    setPlaying(true);
  }, []);
  const onStartHandler = useCallback(() => {
    setIsPlayButton(false);
  }, []);

  return (
    <>
      <VideoPlayerWrapperStyled>
        <ReactPlayer
          url={src}
          // url="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
          height="auto"
          width="100%"
          className="react-player"
          playing={isPlaying}
          controls={!isPlayButton}
          onStart={onStartHandler}
          playsinline
          loop
          {...props}
        />
        {isPlayButton && (
          <MBox
            position="absolute"
            top="0"
            right="0"
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <button
              aria-label="Lire la vidéo"
              type="button"
              onClick={playButtonHandler}
            >
              <IconPlay size="100" />
            </button>
          </MBox>
        )}
      </VideoPlayerWrapperStyled>
    </>
  );
};

ReactVideoPlayer.propTypes = {
  src: propTypes.string.isRequired,
  isAutoPlay: propTypes.bool,
};

ReactVideoPlayer.defaultProps = {
  isAutoPlay: false,
};

export default ReactVideoPlayer;
