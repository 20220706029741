import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import TimerWidget from 'containers/widgets/TimerWidget';
import {
  selectDialog,
  selectGuaranteedAnswerUntil,
  selectIsGuaranteedResponsePending,
} from 'ducks/dialog';
import {
  clearMediaObjectData,
  resetFile,
  selectIsMediaLoadingStarted,
  selectMedia,
  selectMediaIsError,
  setIsMediaLoadingStarted,
  uploadMediaRequest,
} from 'ducks/mediaObject';
import { patchMessageRequest, postMessageRequest } from 'ducks/message';
import { showModal } from 'ducks/modal';
import { postPaymentsRequest } from 'ducks/payment';
import {
  selectAnotherUserGuaranteedResponsePrice,
  selectAnotherUserId,
  selectAnotherUserVideoResponsePrice,
  selectIsPaymentMethodAvailable,
} from 'ducks/user';
import { encodeImageFileAsURL } from 'helpers/imageToBase64';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { visibleMessageRadioItems } from 'constants/boostMessage';
import { modalNames } from 'constants/modalNames';

import noop from 'tools/noop';
import scrollToBottom from 'tools/scrollToChatBottom';

import { Transition, VisuallyHidden } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

import { BoostNotification, BoostRadio } from '../boost';
import { MButton } from '../buttons';
import { MBox } from '../common';
import { MRangeBoost } from '../forms';
import { IconBoost, IconMicrophone, IconVideo } from '../icons';
import { MessageTextarea } from '../messenger';
import { Text } from '../texts';
import { BaseModal } from './BaseModal';

const BoostMessageBodyStyled = styled.div`
  display: grid;
  grid-auto-rows: auto 1fr auto auto;
  grid-row-gap: ${theme.space.primaryMd};
  height: 100%;
`;

const BoostMessageFooterStyled = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  border-top-left-radius: ${theme.radii.mdSecondary};
  border-top-right-radius: ${theme.radii.mdSecondary};
  padding: ${theme.space.primaryMd2} ${theme.space.lg};
  overflow: hidden;
  align-self: end;
`;

const BoostMessageStyled = styled(MBox)`
  min-height: 100vh;
  min-height: fill-available;
  min-height: 100svh; /* stylelint-disable-line */
  height: 100%;
  ${Transition('background, background-image')};
`;

const BoostMessageHintStyled = styled(Text)`
  color: white;
  text-align: center;
  margin-bottom: ${theme.space.primaryMd};
  line-height: ${theme.space.md};
`;

const BoostMessageModal = ({
  messageText,
  messageId,
  boostLevelState,
  setOpen,
  stripePromise,
  scrollToBottomAfterSendMessage,
  ...props
}) => {
  const dispatch = useDispatch();
  const [boostMessageValue, setBoostMessageValue] = useState(messageText);
  const [stateRange, setStateRange] = useState([boostLevelState]);
  const [isGuarantRespPendingWarning, setIsGuarantRespPendingWarning] =
    useState(false);
  const closeModal = useCallback(() => {
    setOpen(false);
    dispatch(clearMediaObjectData());
    dispatch(setIsMediaLoadingStarted(false));
  }, [dispatch, setOpen]);
  const mediaObject = useSelector(selectMedia);
  const isMediaLoadingStarted = useSelector(selectIsMediaLoadingStarted);
  const isMediaObjectError = useSelector(selectMediaIsError);
  const guaranteedResponsePrice = useSelector(
    selectAnotherUserGuaranteedResponsePrice,
  );
  const videoResponsePrice = useSelector(selectAnotherUserVideoResponsePrice);
  const dialog = useSelector(selectDialog);
  const isGuaranteedResponsePending = useSelector(
    selectIsGuaranteedResponsePending,
  );
  const guaranteedAnswerUntil = useSelector(selectGuaranteedAnswerUntil);
  const anotherUserId = useSelector(selectAnotherUserId);
  const isPaymentMethodAvailable = useSelector(selectIsPaymentMethodAvailable);

  const guaranteedAnswerRadioItems = useMemo(() => {
    const resultArray = [];
    if (guaranteedResponsePrice) {
      resultArray.push({
        id: 4,
        value: 3,
        visibleMessageText: 'Vocal',
        time: guaranteedAnswerUntil && (
          <TimerWidget deadline={new Date(guaranteedAnswerUntil)} />
        ),
        icon: <IconMicrophone palette={theme.colors.black} size="16" />,
      });
    }
    if (videoResponsePrice) {
      resultArray.push({
        id: 5,
        value: guaranteedResponsePrice ? 4 : 3,
        visibleMessageText: 'Vidéo',
        time: guaranteedAnswerUntil && (
          <TimerWidget deadline={new Date(guaranteedAnswerUntil)} />
        ),
        icon: <IconVideo palette={theme.colors.black} size="16" />,
      });
    }
    return resultArray;
  }, [guaranteedAnswerUntil, guaranteedResponsePrice, videoResponsePrice]);

  const boostPrices = useMemo(() => {
    if (guaranteedResponsePrice) {
      switch (guaranteedResponsePrice) {
        case 49.99:
          return [4.99, 9.99, 14.99, 49.99, videoResponsePrice || 59.99];
        case 29.99:
          return [1.99, 3.99, 9.99, 29.99, videoResponsePrice || 39.99];
        default:
          return [1.49, 2.49, 3.49, 9.99, videoResponsePrice || 19.99];
      }
    } else if (videoResponsePrice) {
      switch (videoResponsePrice) {
        case 59.99:
          return [4.99, 9.99, 14.99, 49.99, 59.99];
        case 39.99:
          return [1.99, 3.99, 9.99, 29.99, 39.99];
        default:
          return [1.49, 2.49, 3.49, 9.99, 19.99];
      }
    } else {
      return [1.49, 2.49, 3.49, 9.99, 19.99];
    }
  }, [guaranteedResponsePrice, videoResponsePrice]);

  const boostMessage = useMemo(() => {
    const array = [
      {
        id: 1,
        value: 0,
        price: boostPrices[0],
        bgSlider: theme.colors.level1,
        colorSlider: theme.colors.level1,
        radioItemValue: 'Haute',
      },
      {
        id: 2,
        value: 1,
        price: boostPrices[1],
        bgSlider: gradients.boostLevel2,
        colorSlider: theme.colors.level2,
        radioItemValue: 'Élevée',
      },
      {
        id: 3,
        value: 2,
        price: boostPrices[2],
        bgSlider: gradients.boostLevel3,
        colorSlider: theme.colors.level3,
        radioItemValue: 'Maximale',
      },
    ];
    if (guaranteedResponsePrice && !isGuaranteedResponsePending) {
      array.push({
        id: 4,
        value: 3,
        price: boostPrices[3],
        bgSlider: gradients.boostLevel4,
        colorSlider: theme.colors.level4,
        radioItemValue: 'Vocal',
      });
    }
    if (videoResponsePrice && !isGuaranteedResponsePending) {
      array.push({
        id: 5,
        value: guaranteedResponsePrice ? 4 : 3,
        price: boostPrices[4],
        bgSlider: gradients.secondary,
        colorSlider: gradients.secondary,
        radioItemValue: 'Vidéo',
      });
    }
    return array;
  }, [
    boostPrices,
    guaranteedResponsePrice,
    isGuaranteedResponsePending,
    videoResponsePrice,
  ]);

  const MAXIMUM = boostMessage[stateRange]?.radioItemValue === 'Maximale';
  const AUDIO = boostMessage[stateRange]?.radioItemValue === 'Vocal';
  const VIDEO = boostMessage[stateRange]?.radioItemValue === 'Vidéo';

  const clearUploadedMediaHandler = useCallback(() => {
    dispatch(clearMediaObjectData());
  }, [dispatch]);

  const boostMessageHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: modalNames.BOOST_MESSAGE,
        modalProps: {
          messageText: boostMessageValue,
          messageId,
          boostLevelState: stateRange,
          scrollToBottomAfterSendMessage,
          stripePromise,
        },
      }),
    );
  }, [
    dispatch,
    boostMessageValue,
    messageId,
    stateRange,
    scrollToBottomAfterSendMessage,
    stripePromise,
  ]);

  const onUploadMedia = useCallback(
    async event => {
      const file = event.target.files[0];
      if (!/\.png$|\.jpeg$|\.jpg$/.test(file?.name)) {
        event.target.value = '';
        toast.error(
          'The image type must be one of the following: .png, .jpeg or .jpg',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
      } else if (file && file.size < 10 * 1000000) {
        try {
          const fileContents = await encodeImageFileAsURL(file);
          event.target.value = null;
          dispatch(
            showModal({
              modalType: modalNames.CROPPER,
              modalProps: {
                avatar: fileContents,
                isRoundCropper: false,
                withModalClose: false,
                onCropSuccess: croppedFile => {
                  dispatch(setIsMediaLoadingStarted(true));
                  dispatch(resetFile());
                  dispatch(
                    uploadMediaRequest({
                      file: croppedFile,
                      fileName: file.name,
                      onSuccess: noop,
                    }),
                  );
                  boostMessageHandler();
                },
                onGoBackClick: clsModal => {
                  clsModal();
                  dispatch(setIsMediaLoadingStarted(false));
                },
                onClose: () => {
                  dispatch(resetFile());
                },
              },
            }),
          );
        } catch (e) {
          console.warn(e.message);
        }
      } else {
        event.target.value = '';
        toast.error('Image size must be less than 10MB', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
    [boostMessageHandler, dispatch],
  );

  useEffect(() => {
    if (
      isMediaLoadingStarted &&
      ((mediaObject?.id && mediaObject?.preview) || isMediaObjectError)
    ) {
      dispatch(setIsMediaLoadingStarted(false));
    }
  }, [
    dispatch,
    isMediaLoadingStarted,
    isMediaObjectError,
    mediaObject?.id,
    mediaObject?.preview,
  ]);

  const payForMessageBoostHandler = useCallback(
    msg => {
      dispatch(
        postPaymentsRequest({
          values: {
            messageId: msg.id,
          },
          onSuccess: scrollToBottom,
        }),
      );
      window.localStorage.removeItem('share_code');
      closeModal();
    },
    [closeModal, dispatch],
  );

  const sendBoostMessageHandler = useCallback(() => {
    if (boostMessageValue && !/^\s+$/g.test(boostMessageValue)) {
      dispatch(
        postMessageRequest({
          values: {
            body: boostMessageValue,
            receiverId: dialog?.secondUserId || anotherUserId,
            mediaObjectId: mediaObject.id,
            price: boostMessage[stateRange]?.price,
            urlCode: window.localStorage.getItem('share_code') || null,
          },
          onSuccess: msg => {
            scrollToBottomAfterSendMessage();
            payForMessageBoostHandler(msg);
          },
        }),
      );
    }
  }, [
    boostMessageValue,
    dispatch,
    dialog?.secondUserId,
    anotherUserId,
    mediaObject.id,
    boostMessage,
    stateRange,
    payForMessageBoostHandler,
    scrollToBottomAfterSendMessage,
  ]);

  const patchMessageToBoostedHandler = useCallback(() => {
    dispatch(
      patchMessageRequest({
        id: messageId,
        body: {
          mediaObjectId: mediaObject.id,
          price: boostMessage[stateRange]?.price,
        },
        onSuccess: msg => {
          payForMessageBoostHandler(msg);
          scrollToBottom();
        },
      }),
    );
  }, [
    boostMessage,
    dispatch,
    mediaObject.id,
    messageId,
    payForMessageBoostHandler,
    stateRange,
  ]);

  const addPaymentMethodHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: modalNames.ADD_PAYMENT_METHOD,
        modalProps: {
          stripePromise,
          onAddPaymentSuccess: boostMessageHandler,
        },
      }),
    );
  }, [dispatch, boostMessageHandler, stripePromise]);

  const boostButtonClickHandler = useCallback(() => {
    if (isPaymentMethodAvailable) {
      if (messageId) {
        patchMessageToBoostedHandler();
      } else {
        sendBoostMessageHandler();
      }
    } else {
      addPaymentMethodHandler();
    }
    setBoostMessageValue('');
  }, [
    addPaymentMethodHandler,
    isPaymentMethodAvailable,
    messageId,
    patchMessageToBoostedHandler,
    sendBoostMessageHandler,
  ]);

  return (
    <BaseModal
      setOpen={setOpen}
      isBoost
      isBackgroundTransparent
      overflowY="auto"
      {...props}
    >
      <VisuallyHidden as="h1" id="ariaModalTitle">
        Modal avec message boost
      </VisuallyHidden>
      <BoostMessageStyled
        backgroundColor={
          stateRange !== 4 ? boostMessage[stateRange].colorSlider : null
        }
        backgroundImage={boostMessage[stateRange].colorSlider}
      >
        <MBox
          display="grid"
          gridTemplateRows="1fr auto"
          maxWidth={breakpoints.md}
          mx="auto"
          height="100%"
          minHeight="inherit"
          width="100%"
        >
          <BoostMessageBodyStyled>
            <BoostNotification onClick={closeModal} />
            <MBox
              mb="primaryMd"
              mt={
                guaranteedResponsePrice || videoResponsePrice ? '8vh' : '15vh'
              }
            >
              <BoostRadio
                value={stateRange}
                onChange={setStateRange}
                name="radio-donate"
                items={visibleMessageRadioItems}
                ariaLabelledby="visibleMessage"
                label="Visibilité du message :"
                isThreeItem
              />
              {(guaranteedResponsePrice || videoResponsePrice) && (
                <BoostRadio
                  value={stateRange}
                  onChange={setStateRange}
                  name="radio-guaranteed-answer"
                  items={guaranteedAnswerRadioItems}
                  ariaLabelledby="guaranteedAnswer"
                  label="Réponse garantie : "
                  isGuaranteedResponseSend={isGuaranteedResponsePending}
                  onDisabledStateClick={() =>
                    setIsGuarantRespPendingWarning(true)
                  }
                />
              )}
            </MBox>
            {MAXIMUM && (
              <BoostMessageHintStyled type="body3">
                Votre message sera tout en haut <br />
                de la boite de réception du créateur
              </BoostMessageHintStyled>
            )}
            {AUDIO && (
              <BoostMessageHintStyled type="body3">
                Vous serez 100% remboursé <br />
                si le créateur ne répond pas sous 72h
              </BoostMessageHintStyled>
            )}
            {VIDEO && (
              <BoostMessageHintStyled type="body3">
                Vous serez 100% remboursé <br />
                si le créateur ne répond pas en vidéo sous 72h
              </BoostMessageHintStyled>
            )}
            {isGuarantRespPendingWarning && (
              <MBox
                px="primarySm"
                py="xs"
                borderRadius="xsTertiary"
                bg="white"
                mb="primaryMd"
                textAlign="center"
                mx="defaultSpaceX"
              >
                <Text type="body3" color="error" lineHeight="xs">
                  Veuillez attendre que le créateur vous réponde ou que la
                  demande expire dans{' '}
                  {guaranteedAnswerUntil && (
                    <TimerWidget deadline={new Date(guaranteedAnswerUntil)} />
                  )}
                </Text>
              </MBox>
            )}
          </BoostMessageBodyStyled>
          <BoostMessageFooterStyled>
            <MessageTextarea
              isUploadMedia
              rows="1"
              messageBoost
              autoFocus
              placeholder=""
              messageValue={boostMessageValue}
              onChange={setBoostMessageValue}
              mediaObject={mediaObject}
              isMediaLoadingStarted={isMediaLoadingStarted}
              onClearUploadedMedia={clearUploadedMediaHandler}
              onMediaChange={onUploadMedia}
              disabled={!!messageId}
            />
            <MRangeBoost
              value={stateRange}
              onChange={setStateRange}
              guaranteedResponsePrice={
                !isGuaranteedResponsePending && guaranteedResponsePrice
              }
              videoResponsePrice={
                !isGuaranteedResponsePending && videoResponsePrice
              }
              boostMessage={boostMessage}
            />
            <MButton
              icon
              primary
              width="100%"
              disabled={
                !boostMessageValue ||
                /^\s+$/g.test(boostMessageValue) ||
                isMediaLoadingStarted
              }
              onClick={boostButtonClickHandler}
              id="sendBoostMessageButton"
            >
              <Text
                as="span"
                display="flex"
                justifyContent="center"
                height="2rem"
                mr="1rem"
              >
                <IconBoost />
              </Text>
              Payer et booster ce message
            </MButton>
          </BoostMessageFooterStyled>
        </MBox>
      </BoostMessageStyled>
    </BaseModal>
  );
};

BoostMessageModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  scrollToBottomAfterSendMessage: propTypes.func,
  isGuaranteedResponseSend: propTypes.bool,
  messageText: propTypes.string,
  messageId: propTypes.string,
  boostLevelState: propTypes.bool,
  stripePromise: propTypes.instanceOf(Promise),
};

BoostMessageModal.defaultProps = {
  messageText: '',
  messageId: null,
  boostLevelState: 0,
  scrollToBottomAfterSendMessage: noop,
};

export default BoostMessageModal;
