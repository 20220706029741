import { toast } from 'react-toastify';

import { getUserRequest } from 'ducks/user/slice';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import noop from 'tools/noop';

import { forceMyOwnLogout } from './helpers';
import * as services from './services';
import {
  googleLoginRequest,
  loginFailure,
  loginSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  sendVerifyEmailFailure,
  sendVerifyEmailRequest,
  sendVerifyEmailSuccess,
  setVerifyEmailFailure,
  setVerifyEmailRequest,
  setVerifyEmailSuccess,
} from './slice';

function* googleLoginSaga({ payload: { value, onFail } }) {
  try {
    yield call(services.googleLogin, value);
    yield put(loginSuccess());
    yield put(getUserRequest());
  } catch (error) {
    yield call(onFail, error);
    yield put(loginFailure());
    yield call(forceMyOwnLogout);
  }
}

function* registerSaga({ payload: { body, onSuccess } }) {
  try {
    yield call(services.register, body);
    yield put(getUserRequest());
    yield put(registerSuccess());
    yield call(onSuccess);
  } catch (error) {
    yield put(registerFailure());
  }
}

function* sendVerifyEmailSaga({ payload }) {
  try {
    yield call(services.sendVerifyEmail, payload);
    yield put(sendVerifyEmailSuccess());
    yield call(toast.success, `L’e-mail a été envoyé avec succès.`, {
      position: toast.POSITION.TOP_CENTER,
    });
  } catch (error) {
    yield put(sendVerifyEmailFailure());
  }
}

function* setVerifyEmailSaga({ payload: { body, onSuccess = noop } }) {
  try {
    yield call(services.setVerifyEmail, body);
    yield put(getUserRequest({ onSuccess }));
    yield put(setVerifyEmailSuccess());
  } catch (error) {
    yield put(setVerifyEmailFailure());
  }
}

export default function* watchAuth() {
  yield all([
    takeLatest(googleLoginRequest, googleLoginSaga),
    takeLatest(registerRequest, registerSaga),
    takeLatest(sendVerifyEmailRequest, sendVerifyEmailSaga),
    takeLatest(setVerifyEmailRequest, setVerifyEmailSaga),
  ]);
}
