import React, { useCallback } from 'react';

import propTypes from 'prop-types';

import { MButton } from '../buttons';
import { Text } from '../texts';
import { BaseModal } from './BaseModal';

const ConfirmationDeleteCardModal = ({
  isOpen,
  setOpen,
  deletePaymentMethod,
  ...props
}) => {
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  return (
    <BaseModal
      isOpen={isOpen}
      setOpen={setOpen}
      title="Supprimer cette carte ?"
      isCentered
      {...props}
    >
      <Text
        type="label1"
        textAlign="center"
        mb="lg"
        id="ariaModalDesc"
        lineHeight="sm"
      >
        Êtes vous certain de vouloir supprimer cette carte bancaire ?
      </Text>
      <MButton
        type="button"
        scale="sm"
        primary
        width="100%"
        mb="sm"
        onClick={deletePaymentMethod}
      >
        Supprimer la carte
      </MButton>
      <MButton scale="sm" secondary width="100%" onClick={onClose}>
        Annuler
      </MButton>
    </BaseModal>
  );
};

ConfirmationDeleteCardModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
};

export default ConfirmationDeleteCardModal;
