import { memo } from 'react';
import { useFormState } from 'react-final-form';

import propTypes from 'prop-types';

import { MButton } from 'components/buttons';
import { PaymentElementForm } from 'components/finalForm/finalFormInputs';
import { Text } from 'components/texts';

const AddPaymentMethodForm = ({ handleSubmit, stripe, errorMessage }) => {
  const {
    values: { stripeField },
  } = useFormState();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PaymentElementForm fieldName="stripeField" />
      {errorMessage && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          mt="xxs"
          color="error"
        >
          {errorMessage}
        </Text>
      )}
      <MButton
        type="submit"
        disabled={!stripe || !stripeField?.complete}
        width="100%"
        primary
        mt="lg"
      >
        {!stripeField?.value.type || stripeField.value.type === 'card'
          ? 'Ajouter la carte'
          : 'Payer'}
      </MButton>
    </form>
  );
};

AddPaymentMethodForm.propTypes = {
  handleSubmit: propTypes.func,
  stripe: propTypes.any,
  errorMessage: propTypes.string,
};

const component = memo(AddPaymentMethodForm);
component.displayName = 'AddPaymentMethodForm';
export default component;
