import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsFileLoading } from 'ducks/mediaObject';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { CloseFullButton } from 'components/buttons';
import { LoadingSpinner, MBox, Picture } from 'components/common';
import { IconDownload } from 'components/icons';

import { VisuallyHidden } from 'styles/MixinStyle';
import { breakpoints, theme } from 'styles/Theme';

import { BaseModal } from './BaseModal';

const ImageWrapperStyled = styled.div`
  max-width: 90%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
`;

const DownloadButtonStyled = styled.button`
  position: absolute;
  top: ${theme.space.primaryMd};
  right: ${theme.space.primaryMd};
`;

const useImageLoaded = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const refImage = useRef();

  const handleImageOnLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (refImage.current && refImage.current.complete) {
      handleImageOnLoad();
    }
  });

  return [refImage, isLoaded, handleImageOnLoad];
};

const ImageFullModal = ({
  setOpen,
  onDownloadImage,
  $src,
  $srcSetAvif,
  $srcSetWebp,
  ...props
}) => {
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const [refImage, isLoaded, handleImageOnLoad] = useImageLoaded();
  const isLoading = useSelector(selectIsFileLoading);

  return (
    <BaseModal
      setOpen={setOpen}
      isCentered
      isFullMedia
      width={theme.sizes.mediaFullModalWidth}
      maxWidth={breakpoints.md}
      isBackgroundTransparent
      {...props}
    >
      <VisuallyHidden as="h2" id="ariaModalTitle">
        Modal with full image
      </VisuallyHidden>
      <ImageWrapperStyled>
        {!isLoaded && (
          <LoadingSpinner position="absolute" palette={theme.colors.white} />
        )}
        <Picture
          maxHeight="75vh"
          width="100%"
          objectFit="cover"
          borderRadius="sm"
          alt="Image média complète"
          srcSetAvif={$srcSetAvif}
          srcSetWebp={$srcSetWebp}
          src={$src}
          innerRef={refImage}
          onLoad={handleImageOnLoad}
          opacity={!isLoaded ? '0' : '1'}
        />
        <DownloadButtonStyled
          aria-label="Télécharger l'image"
          type="button"
          onClick={onDownloadImage}
          disabled={isLoading}
        >
          {isLoading ? (
            <LoadingSpinner position="relative" size="36" />
          ) : (
            <IconDownload />
          )}
        </DownloadButtonStyled>
      </ImageWrapperStyled>
      <MBox display="flex" justifyContent="center">
        <CloseFullButton onClick={onClose} />
      </MBox>
    </BaseModal>
  );
};

ImageFullModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  $src: propTypes.string,
  $srcSetAvif: propTypes.string,
  $srcSetWebp: propTypes.string,
  onDownloadImage: propTypes.func.isRequired,
};

export default ImageFullModal;
