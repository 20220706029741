import propTypes from 'prop-types';
import styled from 'styled-components';
import { position, space } from 'styled-system';

import { theme } from 'styles/Theme';

const SpinnerWrapperStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  ${position};
  ${space};
`;

const SpinnerStyled = styled.svg`
  .path {
    transform-origin: center;
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = ({ size, my, palette, ...props }) => (
  <SpinnerWrapperStyled {...props}>
    <SpinnerStyled viewBox="0 0 32 32" width={size} my={my}>
      <g fill={palette}>
        <path
          d="M16,32A16,16,0,1,1,32,16,16.019,16.019,0,0,1,16,32ZM16,4A12,12,0,1,0,28,16,12.013,12.013,0,0,0,16,4Z"
          fill={palette}
          opacity="0.4"
        />
        <path
          d="M32,16H28A12.013,12.013,0,0,0,16,4V0A16.019,16.019,0,0,1,32,16Z"
          className="path"
        />
      </g>
    </SpinnerStyled>
  </SpinnerWrapperStyled>
);

LoadingSpinner.defaultProps = {
  size: '50',
  palette: theme.colors.dark,
  my: theme.space.defaultSpaceX,
};

LoadingSpinner.propTypes = {
  size: propTypes.string,
  my: propTypes.string,
  palette: propTypes.string,
};

export default LoadingSpinner;
