import WaveSurferPlayerContainer from 'containers/voiceMessage/WaveSurferPlayerContainer';
import propTypes from 'prop-types';

import noop from 'tools/noop';

const VoiceRecorder = ({
  isMessage,
  buttonRecordText,
  currentVoice,
  isRegistration,
  handleButtonClick,
  disabledVoiceButton,
  isLoading,
  audioFileName,
  handleAudioFileCreation,
  handleAudioFileDeletion,
  isCustomButton,
  ...props
}) => {
  return (
    <WaveSurferPlayerContainer
      currentVoice={currentVoice}
      withRecording
      isMessage={isMessage}
      buttonRecordText={buttonRecordText}
      isRegistration={isRegistration}
      handleButtonClick={handleButtonClick}
      disabledVoiceButton={!!disabledVoiceButton}
      isLoading={isLoading}
      audioFileName={audioFileName}
      handleAudioFileCreation={handleAudioFileCreation}
      handleAudioFileDeletion={handleAudioFileDeletion}
      isCustomButton={isCustomButton}
      {...props}
    />
  );
};

VoiceRecorder.defaultProps = {
  isRegistration: false,
  handleButtonClick: noop,
  handleAudioFileCreation: noop,
  handleAudioFileDeletion: noop,
};

VoiceRecorder.propTypes = {
  isMessage: propTypes.bool,
  currentVoice: propTypes.bool,
  buttonRecordText: propTypes.string,
  isRegistration: propTypes.bool,
  handleButtonClick: propTypes.func,
  disabledVoiceButton: propTypes.any,
  isLoading: propTypes.bool,
  audioFileName: propTypes.string,
  handleAudioFileCreation: propTypes.func,
  handleAudioFileDeletion: propTypes.func,
};

export default VoiceRecorder;
