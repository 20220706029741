/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';

const useWaveform = ({
  containerRef,
  progressColor,
  waveColor,
  url,
  options,
}) => {
  const [wavesurfer, setWavesurfer] = useState(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const ws = WaveSurfer.create({
      url,
      container: containerRef.current,
      barGap: 3,
      barWidth: 3,
      barRadius: 100,
      height: 30,
      waveColor,
      progressColor,
      cursorColor: 'transparent',
      backend: 'MediaElement',
      ...options,
    });

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [containerRef, url]);

  return wavesurfer;
};

export default useWaveform;
