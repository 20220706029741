export const incomeTypes = {
  BOOSTED_MESSAGES: 'Messages boostés',
  SHARED: 'Share',
};

export const incomeTypesList = [
  {
    id: '1',
    text: incomeTypes.BOOSTED_MESSAGES,
    value: 'messages',
  },
  {
    id: '2',
    text: incomeTypes.SHARED,
    value: 'share',
  },
];

export const revenueModalItems = [
  {
    id: '1',
    text: 'Cette semaine',
    value: 'cette-semaine',
  },
  {
    id: '2',
    text: 'Ce mois-ci',
    value: 'ce-mois-ci',
  },
  {
    id: '3',
    text: 'Le mois dernier',
    value: 'le-mois-dernier',
  },
  {
    id: '4',
    text: 'Cette année',
    value: 'cette-annee',
  },
  {
    id: '5',
    text: 'Depuis le début',
    value: 'depuis-le-debut',
  },
];
