import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  clearMediaObjectData,
  resetFile,
  selectIsMediaLoadingStarted,
  selectMedia,
  selectMediaIsError,
  setIsMediaLoadingStarted,
  uploadAudioRequest,
  uploadMediaRequest,
} from 'ducks/mediaObject';
import { createMassMessageRequest, selectDialogMessages } from 'ducks/message';
import { showModal } from 'ducks/modal';
import { selectUser } from 'ducks/user';
import { encodeImageFileAsURL } from 'helpers/imageToBase64';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { modalNames } from 'constants/modalNames';

import getMediaPermissions from 'tools/getMediaPermissions';
import noop from 'tools/noop';

import { Avatar } from 'components/avatar';
import { MBox } from 'components/common';
import { Text } from 'components/texts';
import { VoiceRecorder } from 'components/voiceMessage';

import { CustomScrollBar } from 'styles/MixinStyle';
import { theme } from 'styles/Theme';

import MessageCreator from '../creator/MessageCreator';

const NewConversationCreatorFooterStyled = styled.footer`
  margin: 0 -${theme.space.primaryMd};
  position: sticky;
  bottom: 0;
  background: ${theme.colors.white};
  z-index: 99;
`;

const NewConversationCreatorContentStyled = styled.div`
  flex: 1;
  overflow-y: auto;
  ${CustomScrollBar};
`;

const MassMessageChat = ({
  isAllFans,
  isBoostFans,
  isBestFans,
  multipleFansItems,
  messageText,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const [messageValue, setMessageValue] = useState(messageText);
  const [audioFile, setAudioFile] = useState(null);
  const mediaObject = useSelector(selectMedia);
  const isMediaLoadingStarted = useSelector(selectIsMediaLoadingStarted);
  const isMediaObjectError = useSelector(selectMediaIsError);
  const messagesList = useSelector(selectDialogMessages);
  const user = useSelector(selectUser);

  const reverseMessagesList = useMemo(
    () => [...messagesList].reverse(),
    [messagesList],
  );

  const clearUploadedMediaHandler = useCallback(() => {
    dispatch(clearMediaObjectData());
  }, [dispatch]);

  const clearData = useCallback(() => {
    setOpen(false);
    dispatch(clearMediaObjectData());
    dispatch(setIsMediaLoadingStarted(false));
  }, [dispatch, setOpen]);

  const newMessageHandler = useCallback(() => {
    dispatch(
      showModal({
        modalType: modalNames.NEW_CONVERSATION_CREATOR,
        modalProps: {
          isAllFans,
          isBoostFans,
          isBestFans,
          messageText: messageValue,
        },
      }),
    );
  }, [dispatch, isAllFans, isBestFans, isBoostFans, messageValue]);

  const messageSendHandler = useCallback(
    mId => {
      dispatch(
        createMassMessageRequest({
          body: {
            body: messageValue || null,
            mediaObjectId: typeof mId === 'string' ? mId : mediaObject.id,
            type:
              (isAllFans && 'fans') ||
              (isBoostFans && 'contributors') ||
              (isBestFans && 'top') ||
              '',
          },
          onSuccess: noop,
        }),
      );
      toast.success(`Le message a bien été envoyé à vos fans.`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      clearData();
    },
    [
      dispatch,
      isAllFans,
      isBestFans,
      isBoostFans,
      mediaObject.id,
      messageValue,
      clearData,
    ],
  );

  const onUploadMedia = useCallback(
    async event => {
      const file = event.target.files[0];
      if (!/\.png$|\.jpeg$|\.jpg$/.test(file?.name)) {
        event.target.value = '';
        toast.error(
          'The image type must be one of the following: .png, .jpeg or .jpg',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
      } else if (file && file.size < 10 * 1000000) {
        try {
          const fileContents = await encodeImageFileAsURL(file);
          event.target.value = null;
          dispatch(
            showModal({
              modalType: modalNames.CROPPER,
              modalProps: {
                avatar: fileContents,
                isRoundCropper: false,
                withModalClose: false,
                onCropSuccess: croppedFile => {
                  dispatch(setIsMediaLoadingStarted(true));
                  dispatch(resetFile());
                  dispatch(
                    uploadMediaRequest({
                      file: croppedFile,
                      fileName: file.name,
                      onSuccess: noop,
                    }),
                  );
                  newMessageHandler();
                },
                onGoBackClick: clsModal => {
                  clsModal();
                  dispatch(setIsMediaLoadingStarted(false));
                },
                onClose: () => {
                  dispatch(resetFile());
                },
              },
            }),
          );
        } catch (e) {
          console.warn(e.message);
        }
      } else {
        event.target.value = '';
        toast.error('Image size must be less than 10MB', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
    [newMessageHandler, dispatch],
  );

  useEffect(() => {
    if (
      isMediaLoadingStarted &&
      ((mediaObject?.id && mediaObject?.preview) || isMediaObjectError)
    ) {
      dispatch(setIsMediaLoadingStarted(false));
    }
  }, [
    dispatch,
    isMediaLoadingStarted,
    isMediaObjectError,
    mediaObject?.id,
    mediaObject?.preview,
  ]);

  const uploadVideoHandler = useCallback(
    (file, name, fileType) => {
      dispatch(setIsMediaLoadingStarted(true));
      dispatch(
        uploadMediaRequest({
          file,
          fileName: name || file.name,
          fileType,
        }),
      );
      newMessageHandler();
    },
    [dispatch, newMessageHandler],
  );

  const videoRecordHandler = useCallback(() => {
    getMediaPermissions({
      onPermissionsGranted: () => {
        dispatch(
          showModal({
            modalType: modalNames.VIDEO_RECORD,
            modalProps: {
              sendVideoHandler: uploadVideoHandler,
              onCloseButton: newMessageHandler,
            },
          }),
        );
      },
    });
  }, [dispatch, newMessageHandler, uploadVideoHandler]);

  const handleAudioFileCreation = useCallback(file => {
    setAudioFile(file);
  }, []);

  const handleAudioFileDeletion = useCallback(() => {
    setAudioFile(null);
  }, []);

  const uploadAudioHandler = useCallback(
    (blob, fileName) => {
      dispatch(
        uploadAudioRequest({
          blob,
          fileName,
          onSuccess: messageSendHandler,
        }),
      );
    },
    [dispatch, messageSendHandler],
  );

  const handleSendAudio = useCallback(() => {
    uploadAudioHandler(audioFile, 'audio.mp3');
    setAudioFile(null);
  }, [audioFile, uploadAudioHandler]);

  return (
    <MBox display="flex" flexDirection="column" height="100%">
      <NewConversationCreatorContentStyled>
        <MBox
          borderRadius="sm"
          bg="grayCloud"
          display="flex"
          flexDirection="column"
          alignItems="center"
          p="primaryMd"
        >
          <Avatar
            allFans={isAllFans}
            boostFans={isBoostFans}
            bestFans={isBestFans}
            type="xs"
            mb="md"
          />
          <Text type="label1" mb="sm" as="h3" id="ariaModalDesc">
            {isAllFans && `${multipleFansItems[0].multipleTitle}`}
            {isBoostFans && `${multipleFansItems[1].multipleTitle}`}
            {isBestFans && `${multipleFansItems[2].multipleTitle}`}
          </Text>
          <Text type="body3" color="grayMedium" lineHeight="md">
            {isAllFans && `${multipleFansItems[0].multipleDescription}`}
            {isBoostFans && `${multipleFansItems[1].multipleDescription}`}
            {isBestFans && `${multipleFansItems[2].multipleDescription}`}
          </Text>
        </MBox>
        <Text type="body2" color="grayMedium" mt="sm" mb="primaryMd">
          Le message sera envoyé individuellement à chaque fan
        </Text>
        <MBox pr="primaryMd">
          {reverseMessagesList.map(message => (
            <MessageCreator
              key={message.id}
              id={message.id}
              message={message}
              isOutgoingMessage
              avatarSrc={user?.personalInfo?.avatar?.squarePreviewUrl}
            />
          ))}
        </MBox>
      </NewConversationCreatorContentStyled>

      <NewConversationCreatorFooterStyled>
        <MBox
          display="grid"
          gridTemplateColumns="1fr auto"
          gridColumnGap="sm"
          p="primarySm"
        >
          <VoiceRecorder
            isMessage
            buttonRecordText="Vocal"
            handleAudioFileCreation={handleAudioFileCreation}
            handleAudioFileDeletion={handleAudioFileDeletion}
            handleButtonClick={handleSendAudio}
            isCustomButton
            messageValue={messageValue}
            setMessageValue={setMessageValue}
            messageSendHandler={messageSendHandler}
            onUploadMedia={onUploadMedia}
            mediaObject={mediaObject}
            isMediaLoadingStarted={isMediaLoadingStarted}
            clearUploadedMediaHandler={clearUploadedMediaHandler}
            onVideoRecordClick={videoRecordHandler}
          />
        </MBox>
      </NewConversationCreatorFooterStyled>
    </MBox>
  );
};

MassMessageChat.propTypes = {
  isAllFans: propTypes.bool,
  isBoostFans: propTypes.bool,
  isBestFans: propTypes.bool,
  messageText: propTypes.string,
};

MassMessageChat.defaultProps = {
  messageText: '',
};

export default MassMessageChat;
