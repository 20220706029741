import qs from 'query-string';

const getQueryParams = params => {
  const query = qs.stringify(params, {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true,
  });
  if (!query) {
    return '';
  }
  return `?${query}`;
};

export default getQueryParams;
