import ReactDOMServer from 'react-dom/server';

export const selectAndCopyNodeContents = element => {
  const body = document.body;
  let range;
  let selection;

  if (document.createRange && window.getSelection) {
    range = document.createRange();
    selection = window.getSelection();
    selection.removeAllRanges();
    try {
      range.selectNodeContents(element);
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
    } catch (error) {
      range.selectNode(element);
      selection.addRange(range);
    }
  } else if (body.createTextRange) {
    range = body.createTextRange();
    range.moveToElementText(element);
    range.select();
    document.execCommand('copy');
    selection.removeAllRanges();
  }
};

export const copyElement = (event, element) => {
  event.preventDefault();
  const html = ReactDOMServer.renderToStaticMarkup(element);
  event.clipboardData.setData(
    'text/plain',
    "Your text reader doesn't support html",
  );
  event.clipboardData.setData('text/html', html);
};

export const getHandleCopyContent =
  ({ stringToCopy = '' }) =>
  event => {
    event.preventDefault();
    event.clipboardData.setData('text/plain', stringToCopy);
  };

export const getHandleCopyContentOnClick =
  ({ stringToCopy = '' }) =>
  event => {
    event.preventDefault();
    const listener = copyEvent => {
      getHandleCopyContent({ stringToCopy })(copyEvent);
      event.currentTarget.removeEventListener('copy', listener);
    };
    event.currentTarget.addEventListener('copy', listener);
    selectAndCopyNodeContents(event.currentTarget);
  };

export const setClipboard = text => {
  const type = 'text/plain';
  const textToCopy = async () => {
    return new Promise(resolve => {
      resolve(new Blob([text], { type }));
    });
  };
  // const blob = new Blob([text], { type });
  // const data = [new ClipboardItem({ [type]: blob })];
  const data = [
    new ClipboardItem({
      [type]: textToCopy().then(result => {
        /**
         * We have to return an empty string to the clipboard if something bad happens, otherwise the
         * return type for the ClipBoardItem is incorrect.
         */
        if (!result) {
          return new Promise(resolve => {
            resolve(new Blob([''], { type }));
          });
        }
        return result;
      }),
    }),
  ];

  navigator.clipboard.write(data);
};
