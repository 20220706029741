/* eslint-disable no-console */
import { memo, useCallback, useState } from 'react';
import { Form } from 'react-final-form';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import propTypes from 'prop-types';

import AddPaymentMethodForm from 'components/payment/AddPaymentMethodForm';

const PaymentMethodContainer = ({ savePaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    await stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
      })
      .then(result => {
        console.log(result);
        if (result.error) {
          setErrorMessage(result.error.message);
        }
        savePaymentMethod(result.setupIntent.payment_method);
      })
      .catch(error => {
        console.log(error);
      });
  }, [elements, savePaymentMethod, stripe]);

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <AddPaymentMethodForm
            handleSubmit={handleSubmit}
            stripe={stripe}
            errorMessage={errorMessage}
          />
        );
      }}
    </Form>
  );
};

PaymentMethodContainer.propTypes = {
  savePaymentMethod: propTypes.func,
};

const component = memo(PaymentMethodContainer);
component.displayName = 'PaymentMethodContainer';
export default component;
