/* eslint-disable prettier/prettier */
export const privateMediaDefaultPrices = [
  { defaultPrice: 999, numberOfSubscribers: 10000 },
  { defaultPrice: 1499, numberOfSubscribers: 50000 },
  { defaultPrice: 1499, numberOfSubscribers: 100000 },
  { defaultPrice: 1999, numberOfSubscribers: 200000 },
  { defaultPrice: 1999, numberOfSubscribers: 300000 },
  { defaultPrice: 1999, numberOfSubscribers: 400000 },
  { defaultPrice: 1999, numberOfSubscribers: 500000 },
  { defaultPrice: 2499, numberOfSubscribers: 600000 },
  { defaultPrice: 2499, numberOfSubscribers: 700000 },
  { defaultPrice: 2499, numberOfSubscribers: 800000 },
  { defaultPrice: 2499, numberOfSubscribers: 900000 },
  { defaultPrice: 2999, numberOfSubscribers: 1000000 },
  { defaultPrice: 3999, numberOfSubscribers: 5000000 },
  { defaultPrice: 3999, numberOfSubscribers: 10000000 },
];

export const personalizedVideoDefaultPrices = [
  { defaultPrice: 2999, numberOfSubscribers: 10000 },
  { defaultPrice: 4999, numberOfSubscribers: 50000 },
  { defaultPrice: 4999, numberOfSubscribers: 100000 },
  { defaultPrice: 7999, numberOfSubscribers: 200000 },
  { defaultPrice: 7999, numberOfSubscribers: 300000 },
  { defaultPrice: 7999, numberOfSubscribers: 400000 },
  { defaultPrice: 7999, numberOfSubscribers: 500000 },
  { defaultPrice: 9999, numberOfSubscribers: 600000 },
  { defaultPrice: 9999, numberOfSubscribers: 700000 },
  { defaultPrice: 9999, numberOfSubscribers: 800000 },
  { defaultPrice: 9999, numberOfSubscribers: 900000 },
  { defaultPrice: 13999, numberOfSubscribers: 1000000 },
  { defaultPrice: 19999, numberOfSubscribers: 5000000 },
  { defaultPrice: 19999, numberOfSubscribers: 10000000 },
];

export const guaranteedAnswerDefaultPrices = [
  { defaultPrice: 999, numberOfSubscribers: 10000 },
  { defaultPrice: 1499, numberOfSubscribers: 50000 },
  { defaultPrice: 1499, numberOfSubscribers: 100000 },
  { defaultPrice: 1999, numberOfSubscribers: 200000 },
  { defaultPrice: 1999, numberOfSubscribers: 300000 },
  { defaultPrice: 1999, numberOfSubscribers: 400000 },
  { defaultPrice: 1999, numberOfSubscribers: 500000 },
  { defaultPrice: 2499, numberOfSubscribers: 600000 },
  { defaultPrice: 2499, numberOfSubscribers: 700000 },
  { defaultPrice: 2499, numberOfSubscribers: 800000 },
  { defaultPrice: 2499, numberOfSubscribers: 900000 },
  { defaultPrice: 2999, numberOfSubscribers: 1000000 },
  { defaultPrice: 3999, numberOfSubscribers: 5000000 },
  { defaultPrice: 3999, numberOfSubscribers: 10000000 },
];

export const videoResponseDefaultPrices = [
  { defaultPrice: 1999, numberOfSubscribers: 10000 },
  { defaultPrice: 3999, numberOfSubscribers: 50000 },
  { defaultPrice: 3999, numberOfSubscribers: 100000 },
  { defaultPrice: 5999, numberOfSubscribers: 200000 },
  { defaultPrice: 5999, numberOfSubscribers: 300000 },
  { defaultPrice: 5999, numberOfSubscribers: 400000 },
  { defaultPrice: 5999, numberOfSubscribers: 500000 },
  { defaultPrice: 7999, numberOfSubscribers: 600000 },
  { defaultPrice: 7999, numberOfSubscribers: 700000 },
  { defaultPrice: 7999, numberOfSubscribers: 800000 },
  { defaultPrice: 7999, numberOfSubscribers: 900000 },
  { defaultPrice: 9999, numberOfSubscribers: 1000000 },
  { defaultPrice: 10999, numberOfSubscribers: 5000000 },
  { defaultPrice: 10999, numberOfSubscribers: 10000000 },
];

export const estimatedEarningsInEuros = [
  {
    numberOfSubscribers: 10000,
    profilePrive: 2000,
    messages: 1600,
    strikeShare: 1000,
  },
  {
    numberOfSubscribers: 50000,
    profilePrive: 6000,
    messages: 4800,
    strikeShare: 1800,
  },
  {
    numberOfSubscribers: 100000,
    profilePrive: 8000,
    messages: 6400,
    strikeShare: 2400,
  },
  {
    numberOfSubscribers: 200000,
    profilePrive: 12000,
    messages: 9600,
    strikeShare: 3600,
  },
  {
    numberOfSubscribers: 300000,
    profilePrive: 16000,
    messages: 12800,
    strikeShare: 4800,
  },
  {
    numberOfSubscribers: 400000,
    profilePrive: 24000,
    messages: 19200,
    strikeShare: 7200,
  },
  {
    numberOfSubscribers: 500000,
    profilePrive: 32000,
    messages: 25600,
    strikeShare: 9600,
  },
  {
    numberOfSubscribers: 600000,
    profilePrive: 40000,
    messages: 32000,
    strikeShare: 12000,
  },
  {
    numberOfSubscribers: 700000,
    profilePrive: 48000,
    messages: 38400,
    strikeShare: 14400,
  },
  {
    numberOfSubscribers: 800000,
    profilePrive: 56000,
    messages: 44800,
    strikeShare: 16800,
  },
  {
    numberOfSubscribers: 900000,
    profilePrive: 64000,
    messages: 51200,
    strikeShare: 19200,
  },
  {
    numberOfSubscribers: 1000000,
    profilePrive: 72000,
    messages: 57600,
    strikeShare: 21600,
  },
  {
    numberOfSubscribers: 5000000,
    profilePrive: 320000,
    messages: 256000,
    strikeShare: 96000,
  },
  {
    numberOfSubscribers: 10000000,
    profilePrive: 960000,
    messages: 768000,
    strikeShare: 288000,
  },
];

export const defaultPrices = {
  MIN_PRIVATE_MEDIA_PRICE: 99,
  MIN_PRICE: 99,
  MAX_PRICE: 9999,
};
