/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

const name = 'auth';

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    googleLoginRequest(state) {
      state.loading = true;
    },
    loginSuccess(state) {
      state.loading = false;
    },
    loginFailure(state) {
      state.loading = false;
    },
    registerRequest(state) {
      state.loading = true;
    },
    registerSuccess(state) {
      state.loading = false;
    },
    registerFailure(state) {
      state.loading = false;
    },
    resetGoToPage(state) {
      state.goToPage = null;
    },
    sendVerifyEmailRequest(state) {
      state.loading = true;
    },
    sendVerifyEmailSuccess(state) {
      state.loading = false;
    },
    sendVerifyEmailFailure(state) {
      state.loading = false;
    },
    setVerifyEmailRequest(state) {
      state.loading = true;
    },
    setVerifyEmailSuccess(state) {
      state.loading = false;
    },
    setVerifyEmailFailure(state) {
      state.loading = false;
      state.verifyEmailLinkExpired = true;
    },
    resetVerifyEmail(state) {
      state.verifyEmailLinkExpired = false;
    },
  },
});

export const {
  resetGoToPage,
  registerFailure,
  resetViolations,
  registerRequest,
  registerSuccess,
  googleLoginRequest,
  loginSuccess,
  loginFailure,
  sendVerifyEmailRequest,
  sendVerifyEmailSuccess,
  sendVerifyEmailFailure,
  setVerifyEmailRequest,
  setVerifyEmailSuccess,
  setVerifyEmailFailure,
  resetVerifyEmail,
} = authSlice.actions;

export default authSlice.reducer;
