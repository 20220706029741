import { createSelector } from 'reselect';

import {
  defaultDateFormat,
  revenuePaidDateFormat,
  revenuePaidTimeFormat,
} from 'constants/dateFormatters';

import { formatPrice, numberWithSpaces } from 'tools/stringFormatter';

export const selectPayments = state => state.payment.payments;
export const selectIsLoading = state => state.payment.loading;
export const selectIsPaymentsLoading = state => state.payment.isPaymentsLoading;
export const selectPaymentsPage = state => state.payment.paymentsPage;
export const selectHasMore = state => state.payment.hasMore;
export const selectHasMoreRevenueItems = state =>
  state.payment.hasMoreRevenueItems;
export const selectRevenue = state => state.payment.revenue;
export const selectRevenueItems = state => state.payment.revenueItems;
export const selectRevenuePage = state => state.payment.revenuePage;
export const selectRevenueAmount = state => state.payment.revenueAmount;
export const selectPaydMessageId = state => state.payment.paydMessageId;

export const selectRevenueAmountPrepared = createSelector(
  selectRevenueAmount,
  revenueAmount =>
    revenueAmount
      ? {
          totalIncome: numberWithSpaces(revenueAmount?.totalIncome),
          messagesIncome: numberWithSpaces(revenueAmount?.messagesIncome),
          shareIncome: numberWithSpaces(revenueAmount?.shareIncome),
        }
      : { isLoading: true },
);

export const selectRevenuePrepared = createSelector(selectRevenue, revenue =>
  revenue?.map(item => ({
    id: item?.paidAt,
    name: item?.payerPublicName || item?.receiverPublicName,
    transaction:
      item?.type && 'Message' /* Only 'Message' in current version */,
    image:
      item?.payerAvatar?.squarePreviewUrl ||
      item?.receiverAvatar?.squarePreviewUrl,
    imageAvif:
      item?.payerAvatar?.squarePreviewAvifUrl ||
      item?.receiverAvatar?.squarePreviewAvifUrl,
    imageWebp:
      item?.payerAvatar?.squarePreviewWebpUrl ||
      item?.receiverAvatar?.squarePreviewWebpUrl,
    date: revenuePaidDateFormat(item?.paidAt),
    time: revenuePaidTimeFormat(item?.paidAt),
    ...(item?.receiverUsername && { username: item?.receiverUsername }),
    total: formatPrice(item?.amount.totalAmount),
    ...(item?.amount.commissionAmount && {
      comission: formatPrice(item?.amount.commissionAmount),
    }),
    income: formatPrice(item?.amount.creatorAmount, true),
    avatarChecked: !!item?.receiverId,
    ...(item?.amount.commissionPercentage && {
      yourPart: `${item?.amount.commissionPercentage} %`,
    }),
  })),
);

export const selectPaymentsPrepared = createSelector(
  selectPayments,
  payments =>
    payments &&
    payments.map(payment => {
      return {
        id: payment.id,
        avatarChecked: true, // temporary
        name: payment.receiverPublicName,
        transaction: 'Super message', // only 'Super message' in V1
        image: payment.receiverAvatar?.squarePreviewUrl,
        imageAvif: payment.receiverAvatar?.squarePreviewAvifUrl,
        imageWebp: payment.receiverAvatar?.squarePreviewWebpUrl,
        link: payment.invoiceLink,
        date: defaultDateFormat(payment.createdAt),
        price: formatPrice(payment.message.price),
        refunded: payment.message.refunded,
        username: payment.receiverUsername,
      };
    }),
);
