import { useCallback } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import propTypes from 'prop-types';
import styled from 'styled-components';

import { revenueModalItems } from 'constants/revenueConstants';

import { onChangeEventHandler } from 'tools/handlers';
import noop from 'tools/noop';

import { Heading3 } from 'components/texts';

import { GradientText, InputFocusVisible } from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

const IncomePeriodWrapper = styled(BottomSheet)`
  [data-rsbs-overlay] {
    border-top-left-radius: ${theme.radii.mdSecondary};
    border-top-right-radius: ${theme.radii.mdSecondary};
    max-width: ${breakpoints.md};
    margin: 0 auto;
  }

  [data-rsbs-backdrop],
  [data-rsbs-overlay],
  [data-rsbs-root]:after {
    z-index: 99998;
  }

  [data-rsbs-header] {
    height: ${theme.sizes.swipeModalHeaderHeight};

    &:before {
      top: ${theme.space.primaryMd};
      width: ${theme.sizes.swipeModalHeaderElemWidth};
      background: ${theme.colors.grayLight};
      border-radius: ${theme.radii.xl};
    }
  }

  [data-rsbs-content] {
    padding-bottom: ${theme.space.primaryMd};
  }
`;

const IncomePeriodLabelStyled = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${theme.space.primaryMd};
  ${props =>
    props.active &&
    `background: ${gradients.primary}; background-blend-mode: overlay; background-color: rgba(255, 255, 255, 0.9);`};
`;

const IncomePeriodInputStyled = styled.input`
  ${InputFocusVisible};
`;

const IncomePeriodTextStyled = styled.span`
  cursor: pointer;
  line-height: ${theme.lineHeights.md};
  ${props =>
    props.active
      ? `${GradientText(gradients.primary)}; font-weight: ${
          theme.fontWeights.medium
        };`
      : `color: ${theme.colors.black}; font-weight: ${theme.fontWeights.regular}`};
`;

const IncomePeriodModal = ({ value, onChange, isOpen, setOpen }) => {
  const onDismiss = () => {
    setOpen(false);
  };

  const changeHandler = useCallback(
    event => {
      return onChangeEventHandler(event, onChange);
    },
    [onChange],
  );

  return (
    <>
      <IncomePeriodWrapper
        open={isOpen}
        onDismiss={onDismiss}
        snapPoints={({ minHeight }) => minHeight}
      >
        <Heading3 as="h2" lineHeight="sm" px="primaryMd" py="sm">
          Période
        </Heading3>
        {revenueModalItems.map(item => (
          <IncomePeriodLabelStyled
            active={value === item.value}
            key={item.text}
          >
            <IncomePeriodInputStyled
              type="radio"
              name="income-period"
              value={item.value}
              onChange={changeHandler}
            />
            <IncomePeriodTextStyled active={value === item.value}>
              {item.text}
            </IncomePeriodTextStyled>
          </IncomePeriodLabelStyled>
        ))}
      </IncomePeriodWrapper>
    </>
  );
};

IncomePeriodModal.defaultProps = {
  value: '',
  onChange: noop,
};

IncomePeriodModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
  value: propTypes.string,
  onChange: propTypes.func,
};

export default IncomePeriodModal;
