import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { countriesConstList } from 'constants/countriesList';
import socialNetworksNames from 'constants/socialNetworksNames';
import { userRoles } from 'constants/userRoles';

export const selectUser = state => state.user.currentUser;
export const selectUserCertified = state => state.user.currentUser?.certified;
export const selectUserIsVerifiedEmail = state =>
  state.user.currentUser?.verifiedEmail;
export const selectUserNickname = state => state.user.currentUser?.nickname;
export const selectLoadedUser = state => state.user.anotherUser;
export const selectIsInitialLoading = state => state.user.initialLoading;
export const selectLoadingStatus = state => state.user.loading;
export const selectIsAuthenticated = state => !!state.user.currentUser;
export const selectIsVerified = state =>
  !!state.user.currentUser?.roles.includes(userRoles.ROLE_VERIFIED);
export const selectIsCertified = state =>
  !!state.user.currentUser?.roles.includes(userRoles.ROLE_CERTIFIED);
export const selectWasThereCertificationRequest = state =>
  !!state.user.currentUser?.certification;
export const selectIsOptionsEmpty = state =>
  !state.user.currentUser?.accountOptions;
export const selectIsModalToShow = (state, modalName) =>
  !!state.user.currentUser?.modalsToShow.includes(modalName);
export const selectIsFan = state => state.user.currentUser?.fan;
export const selectIsCreator = state => state.user.currentUser?.creator;
export const selectIsPrivateProfileOption = state =>
  !!state.user.currentUser?.accountOptions?.PRIVATE_PROFILE;
export const selectIsMessagesOption = state =>
  !!state.user.currentUser?.accountOptions?.MESSAGES;
export const selectMessagesOption = state =>
  state.user.currentUser?.accountOptions?.MESSAGES;
export const selectPrivateProfileOption = state =>
  state.user.currentUser?.accountOptions?.PRIVATE_PROFILE;
export const selectIsPersonalizedVideosOption = state =>
  !!state.user.currentUser?.accountOptions?.PERSONALIZED_VIDEOS;
export const selectOptions = state => state.user.currentUser?.accountOptions;
export const selectPrivateProfileEnableStatus = state =>
  state.user.currentUser?.privateProfile;
export const selectMessagesEnableStatus = state =>
  state.user.currentUser?.messages;
export const selectPaymentInfo = state => state.user.currentUser?.paymentInfo;
export const selectCurrentUserId = state => state.user.currentUser?.id;
export const selectAnotherUserId = state => state.user.anotherUser?.id;
export const selectIsPaymentMethodAvailable = state =>
  state.user.currentUser?.paymentMethodAvailable;
export const selectHasDeletionRequest = state =>
  state.user.currentUser?.hasDeletionRequest;
export const selectNewAvatarUrl = state => state.user.newAvatarUrl;

export const selectNumberOfSubscribers = state => {
  if (state.user.currentUser?.socialNetworks.length !== 0) {
    const premium = state.user.currentUser?.socialNetworks.find(
      item => item.name === socialNetworksNames.PREMIUM,
    );

    if (premium) {
      return premium.subscribers;
    }

    const subscribersNumber = state.user.currentUser?.socialNetworks.map(
      item => item.subscribers,
    );

    return Math.max(...subscribersNumber);
  }

  return 0;
};
export const selectCountries = state => state.user.countries;
export const selectChangingBio = state => state.user.isChangingBio;
export const selectIsAnotherUserHasPrivateProfileOption = state =>
  !!state.user.anotherUser?.accountOptions?.PRIVATE_PROFILE;
export const selectIsAnotherUserHasMessagesOption = state =>
  !!state.user.anotherUser?.accountOptions?.MESSAGES;
export const selectAnotherUserPrivateProfileOption = state =>
  state.user.anotherUser?.accountOptions?.PRIVATE_PROFILE;
export const selectAnotherUserMessagesOption = state =>
  state.user.anotherUser?.accountOptions?.MESSAGES;
export const selectIsAnotherUserFan = state =>
  !!state.user.anotherUser?.roles?.includes(userRoles.ROLE_FAN);
export const selectIsAnotherUserCreator = state =>
  !!state.user.anotherUser?.roles?.includes(userRoles.ROLE_CREATOR);
export const selectIsAnotherUserLoaded = state => !!state.user.anotherUser;
export const selectIsResponseVideoOption = state =>
  !!state.user.currentUser?.accountOptions?.MESSAGES?.PRICE_VIDEO_RESPONSE;
export const selectPriceVideoResponse = state =>
  state.user.currentUser?.accountOptions?.MESSAGES?.PRICE_VIDEO_RESPONSE;
export const selectAnotherUserData = state => state.user.anotherUser;
export const selectIsAnotherUserCertified = state =>
  !!state.user.anotherUser?.certification;
export const selectCreators = state => state.user.creators;
export const selectNewCreators = state => state.user.newCreators;
export const selectTopCreators = state =>
  state.user.topCreators?.subscriptionsTop || [];
export const selectTopMessages = state =>
  state.user.topCreators?.messagesTop || [];
export const selectLocale = state => state.user.currentUser?.locale;
export const selectPrivateProfile = state =>
  state.user.currentUser?.privateProfile;
export const selectIsUserLoading = state => state.user.loading;
export const selectIsTopCreatorsLoading = state =>
  state.user.topCreatorsLoading;
export const selectError = state => state.user.error;
export const selectCreatorExplorePage = state => state.user.creatorExplorePage;
export const selectNewCreatorsPage = state => state.user.newCreatorsPage;
export const selectHasMore = state => state.user.hasMore;
export const selectHasMoreNewCreators = state => state.user.hasMoreNewCreators;

export const selectIsSearchLoading = state => state.user.isSearchLoading;
export const selectAnotherUserGuaranteedResponsePrice = state =>
  state.user.anotherUser?.accountOptions?.guaranteedResponsePrice;
export const selectAnotherUserVideoResponsePrice = state =>
  state.user.anotherUser?.accountOptions?.videoResponsePrice;
export const selectIsWelcomeAudioLoading = state =>
  state.user.isWelcomeAudioLoading;
export const selectMailingSettings = state =>
  state.user?.currentUser?.mailingSettings;
export const selectIsMailingSettingsLoading = state =>
  state?.user.isMailingSettingsLoading;

export const selectUserProfilePrepared = createSelector(
  [selectUser, (state, locale) => locale],
  (
    { avatar, nickname, bio, city, socialNetworks, dateOfBirth, categories },
    locale,
  ) => {
    const socialNetwork = name =>
      socialNetworks.find(socNet => socNet.name === name);

    return {
      avatarField: avatar,
      nickname,
      bio,
      city,
      dateOfBirth: new Date(dateOfBirth),
      categories: categories[0][`${locale}Value`],
      socialNetworks: {
        instagram: socialNetwork(socialNetworksNames.INSTAGRAM)?.nickname || '',
        snapchat: socialNetwork(socialNetworksNames.SNAPCHAT)?.nickname || '',
        tiktok: socialNetwork(socialNetworksNames.TIKTOK)?.nickname || '',
        twitter: socialNetwork(socialNetworksNames.TWITTER)?.nickname || '',
        youtube: socialNetwork(socialNetworksNames.YOUTUBE)?.nickname || '',
        facebook: socialNetwork(socialNetworksNames.FACEBOOK)?.nickname || '',
        spotify: socialNetwork(socialNetworksNames.SPOTIFY)?.nickname || '',
        premium: {
          nickname: socialNetwork(socialNetworksNames.PREMIUM)?.nickname || '',
          subscribers: socialNetwork(socialNetworksNames.PREMIUM)?.subscribers,
        },
      },
    };
  },
);

export const selectUserInfoPrepared = createSelector(
  selectUser,
  ({ name, surname, email, dateOfBirth, gender, company, phone }) => ({
    name,
    surname,
    email,
    dateOfBirth: new Date(dateOfBirth),
    gender,
    company: company || {},
    phone: phone || '',
  }),
);

export const selectCountriesPrepared = createSelector(
  selectCountries,
  countries =>
    countries?.length
      ? sortBy(
          countries?.map(({ cca3, name: { common } }) => ({
            value: cca3,
            label: common,
          })),
          ['label'],
        )
      : countriesConstList,
);

export const selectCreatorPublicInfoPrepared = createSelector(
  selectUser,
  ({ personalInfo, accountInfo, socialNetworks, mediaPreviews }) => {
    const findSocialNetworkLink = name =>
      socialNetworks.find(network => network.name === name)?.link;

    return {
      firstName: personalInfo?.firstName || '',
      lastName: personalInfo?.lastName || '',
      dateOfBirth: personalInfo?.dateOfBirth || '',
      gender: personalInfo?.gender || '',
      avatarUrl: personalInfo?.avatar?.squarePreviewUrl || '',
      bio: personalInfo?.bio || '',
      publicName: accountInfo?.publicName || '',
      username: accountInfo?.username || '',
      instagram: findSocialNetworkLink(socialNetworksNames.INSTAGRAM) || '',
      snapchat: findSocialNetworkLink(socialNetworksNames.SNAPCHAT) || '',
      tiktok: findSocialNetworkLink(socialNetworksNames.TIKTOK) || '',
      twitter: findSocialNetworkLink(socialNetworksNames.TWITTER) || '',
      youtube: findSocialNetworkLink(socialNetworksNames.YOUTUBE) || '',
      facebook: findSocialNetworkLink(socialNetworksNames.FACEBOOK) || '',
      category: accountInfo?.category || '',
      mediaPreview0: mediaPreviews[0] || null,
      mediaPreview1: mediaPreviews[1] || null,
      mediaPreview2: mediaPreviews[2] || null,
      mediaPreview3: mediaPreviews[3] || null,
      mediaPreview4: mediaPreviews[4] || null,
      mediaPreview5: mediaPreviews[5] || null,
    };
  },
);

export const selectIsRegistrationCompleted = createSelector(
  selectUser,
  // eslint-disable-next-line consistent-return
  user => {
    if (user) {
      if (user.certified) {
        return true;
      }
      if (
        !user.certified &&
        !!user.verifiedEmail &&
        !!user.email &&
        !!user?.phone?.code &&
        !!user?.phone?.number &&
        !!user?.personalInfo?.firstName &&
        !!user?.personalInfo?.lastName &&
        !!user?.personalInfo?.dateOfBirth &&
        !!user?.personalInfo?.gender &&
        !!user?.personalInfo?.bio &&
        !!user?.personalInfo?.avatar &&
        !!user?.accountOptions?.guaranteedResponsePrice &&
        !!user?.accountOptions?.videoResponsePrice &&
        !!user?.accountInfo?.username &&
        !!user?.accountInfo?.publicName &&
        !!user?.accountInfo?.category &&
        !!user?.personalInfo?.welcomeAudioUrl &&
        user?.socialNetworks.length >= 1
      ) {
        return true;
      }
      return false;
    }
  },
);
