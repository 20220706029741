import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import {
  savePaymentMethodRequest,
  selectStripeToken,
  setupPaymentMethodRequest,
} from 'ducks/paymentMethods';
import { setIsPaymentMethodAvailable } from 'ducks/user';
import propTypes from 'prop-types';

import { LoadingSpinner } from 'components/common';

import { theme } from 'styles/Theme';

import PaymentMethodContainer from './PaymentMethodContainer';

const AddPaymentMethodFormContainer = ({
  stripePromise,
  setModalOpen,
  onAddPaymentSuccess,
}) => {
  const dispatch = useDispatch();

  const stripeToken = useSelector(selectStripeToken);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorBackground: theme.colors.white,
      colorText: theme.colors.dark,
      colorPrimary: theme.colors.dark,
      colorDanger: theme.colors.error,
      colorLogo: 'light',
      fontFamily: theme.fonts.main,
      fontSizeBase: '1rem',
      fontWeightLight: theme.fontWeights.regular,
      fontWeightNormal: theme.fontWeights.medium,
      fontWeightBold: theme.fontWeights.semiBold,
      spacingUnit: '6px',
      borderRadius: theme.radii.xs,
      colorDangerText: theme.colors.error,
      colorWarningText: theme.colors.error,
      colorTextPlaceholder: theme.colors.grayLight,
    },
    rules: {
      '.Input': {
        borderColor: theme.colors.grayLight,
        boxShadow: 'none',
        transition: 'none',
        fontWeight: theme.fontWeights.regular,
        lineHeight: '1.5',
        padding: '14px',
      },
      '.Input:focus': {
        outline: 'none',
        boxShadow: 'none',
        border: `${theme.borderWidths.sm} solid ${theme.colors.primary}`,
        padding: '13px',
      },
      '.Input--invalid': {
        boxShadow: 'none',
        border: `${theme.borderWidths.sm} solid ${theme.colors.error}`,
        padding: '13px',
      },
      '.Error': {
        fontWeight: theme.fontWeights.regular,
        lineHeight: '1.15',
      },
    },
  };

  const options = {
    clientSecret: stripeToken,
    appearance,
    locale: 'fr',
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800',
      },
    ],
  };

  useEffect(() => {
    dispatch(setupPaymentMethodRequest());
  }, [dispatch]);

  const onSuccessSavePayment = useCallback(() => {
    setModalOpen(false);
    dispatch(setIsPaymentMethodAvailable(true));
    if (onAddPaymentSuccess) {
      onAddPaymentSuccess();
    }
  }, [dispatch, onAddPaymentSuccess, setModalOpen]);

  const savePaymentMethod = useCallback(
    paymentMethodId => {
      dispatch(
        savePaymentMethodRequest({
          paymentMethodId,
          onSuccess: onSuccessSavePayment,
        }),
      );
    },
    [dispatch, onSuccessSavePayment],
  );

  if (!stripeToken) {
    return <LoadingSpinner />;
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentMethodContainer savePaymentMethod={savePaymentMethod} />
    </Elements>
  );
};

AddPaymentMethodFormContainer.propTypes = {
  stripePromise: propTypes.instanceOf(Promise),
  onAddPaymentSuccess: propTypes.func,
};

const component = memo(AddPaymentMethodFormContainer);
component.displayName = 'AddPaymentMethodFormContainer';
export default AddPaymentMethodFormContainer;
