const formatAudioTime = durationInSeconds => {
  const parts = [];
  let leftSeconds = Math.floor(durationInSeconds);

  const hours = Math.floor(leftSeconds / (60 * 60));

  leftSeconds %= 60 * 60;

  const minutes = Math.floor(leftSeconds / 60);

  leftSeconds %= 60;

  if (hours > 0) {
    parts.push(String(hours).padStart(2, '0'));
  }
  parts.push(String(minutes).padStart(2, '0'));
  parts.push(String(leftSeconds).padStart(2, '0'));

  return `${parts.join(':')}`;
};

export default formatAudioTime;
