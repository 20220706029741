import propTypes from 'prop-types';
import styled from 'styled-components';

import noop from 'tools/noop';

import { MBox } from 'components/common';
import { IconBoost, IconCross } from 'components/icons';
import { Text } from 'components/texts';

import { breakpoints, theme } from 'styles/Theme';

const BoostNotificationStyled = styled.article`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${theme.space.sm};
  color: ${theme.colors.white};
  padding: ${theme.space.primaryMd};
  background-color: rgba(0, 0, 0, 0.2);
  max-width: ${breakpoints.md};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const BoostNotification = ({ onClick, ...props }) => {
  return (
    <BoostNotificationStyled {...props}>
      <MBox alignSelf="center">
        <IconBoost size="30" />
      </MBox>
      <MBox>
        <Text type="subtitle1" as="h2" mb="xs">
          Envoyer un Super Message
        </Text>
        <Text type="body2">Attire l’attention, obtiens une réponse !</Text>
      </MBox>
      <MBox alignSelf="flex-start">
        <button
          type="button"
          aria-label="Fermer modale boost"
          onClick={onClick}
        >
          <IconCross />
        </button>
      </MBox>
    </BoostNotificationStyled>
  );
};

BoostNotification.defaultProps = {
  onClick: noop,
};

BoostNotification.propTypes = {
  onClick: propTypes.func,
};

export default BoostNotification;
