import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { hideModal } from 'ducks/modal';
import propTypes from 'prop-types';

import { routes } from 'constants/routes';

import { MButton } from 'components/buttons';
import { IconWarning } from 'components/icons';
import { Text } from 'components/texts';

import { BaseModal } from './BaseModal';

const ErrorPaymentModal = ({ isOpen, setOpen, ...props }) => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  return (
    <BaseModal
      isOpen={isOpen}
      setOpen={setOpen}
      title="Votre paiement a été refusé"
      titleColor="error"
      isCentered
      {...props}
    >
      <IconWarning size="48" mb="md" />
      <Text
        type="label1"
        textAlign="center"
        mb="lg"
        id="ariaModalDesc"
        lineHeight="md"
      >
        Vérifiez si votre carte n’est pas expirée ou ajoutez un autre moyen de
        paiement.
      </Text>
      <MButton
        as={Link}
        to={routes.SETTINGS_PAYMENT_METHODS}
        onClick={() => dispatch(hideModal())}
        type={null}
        scale="sm"
        width="100%"
        mb="sm"
        secondary
      >
        Modifier mes moyens de paiement
      </MButton>
      <MButton scale="sm" primary width="100%" onClick={onClose}>
        Retour
      </MButton>
    </BaseModal>
  );
};

ErrorPaymentModal.propTypes = {
  setOpen: propTypes.func.isRequired,
  isOpen: propTypes.bool,
};

export default ErrorPaymentModal;
